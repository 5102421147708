import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const CustomSequence = ({
  condition,
  choices,
  values,
  handler,
  index,
  status,
}: {
  condition: boolean | string | undefined
  choices: string[]
  values: string[]
  handler: Function
  index: number
  status: boolean | undefined
}) => {
  const moveRight = (key: number) => {
    const choices2 = [...choices]
    const b = choices2[key]
    choices2[key] = choices2[key + 1]
    choices2[key + 1] = b
    choices = choices2
    handler(index, choices)
  }

  const moveLeft = (key: number) => {
    const choices2 = [...choices]
    const b = choices2[key]
    choices2[key] = choices2[key - 1]
    choices2[key - 1] = b
    choices = choices2
    handler(index, choices)
  }
  return (
    <div>
      <ul className="sequencing-list ui-sortable">
        {values.map((value: string, i: number) => {
          return (
            <>
              <li
                className="btn-group group-sequencing ui-sortable-handle"
                key={value}
              >
                <button
                  hidden={
                    i === 0 ||
                    condition === true ||
                    condition === 'skip' ||
                    status === true
                  }
                  disabled={
                    i === 0 ||
                    condition === true ||
                    condition === 'skip' ||
                    status === true
                  }
                  className="btn btn-default btn-sequencing"
                  onClick={() => {
                    moveLeft(i)
                  }}
                >
                  <FontAwesomeIcon icon={'arrow-left'} />
                </button>
                <span
                  className={`${
                    condition === true || status === true
                      ? 'alert-success'
                      : condition === 'skip'
                      ? 'alert-danger'
                      : ''
                  } btn btn-default btn-sequencing`}
                >
                  {value}
                </span>
                <button
                  hidden={
                    i === choices.length - 1 ||
                    condition === true ||
                    condition === 'skip' ||
                    status === true
                  }
                  disabled={
                    i === choices.length - 1 ||
                    condition === true ||
                    condition === 'skip' ||
                    status === true
                  }
                  className="btn btn-default btn-sequencing"
                  onClick={() => {
                    moveRight(i)
                  }}
                >
                  <FontAwesomeIcon icon={'arrow-right'} />
                </button>
              </li>
            </>
          )
        })}
        <hr className="clearfix"></hr>
      </ul>
    </div>
  )
}

export default CustomSequence
