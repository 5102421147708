import {
  increaseAttemptCounter,
  setCardCondition,
  setClozeAnswer,
  setClozeStatus,
  setGuide,
  skipCloze,
} from '../../../store/quiz/quiz'
import { useDispatch, useSelector } from 'react-redux'

import CustomInput from './CustomInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ICard } from '../../../store/quiz/interfaces'
import ModalInstructionsCloze from './ModalInstructionsCloze'
import React from 'react'
import { RootState } from '../../../store'
import { useTranslation } from 'react-i18next'

const ClozeCard = ({ card }: { card: ICard }) => {
  const { currentPage } = useSelector((state: RootState) => state.quiz)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const compareArrays = function(a1: string[], a2: string[]) {
    let i = a1.length
    if (i !== a2.length) return false
    while (i--) {
      if (a1[i] !== a2[i]) return false
    }
    return true
  }

  const skip = () => {
    dispatch(
      setCardCondition({
        page: currentPage,
        cardId: card.id,
        condition: 'skip',
      })
    )
    dispatch(
      setGuide({
        page: currentPage,
        cardId: card.id,
        condition: 'skip',
      })
    )
    dispatch(
      skipCloze({
        page: currentPage,
        cardId: card.id,
      })
    )
  }

  const checkAnswer = () => {
    const keywords = card.question_data.elements
      .filter(e => e.key !== '')
      .map(e => {
        return e.key
      })
    const fieldValues = card.question_data.elements
      .filter(e => e.type === 'input')
      .map(e => {
        return e.data
      })

    card.question_data.elements
      .filter(e => e.type === 'input')
      .map(e => {
        if (e.key === e.data) {
          dispatch(
            setClozeStatus({
              page: currentPage,
              cardId: card.id,
              status: true,
              key: e.key,
            })
          )
        }
      })

    const correct = compareArrays(keywords, fieldValues)
    dispatch(
      setCardCondition({
        page: currentPage,
        cardId: card.id,
        condition: correct,
      })
    )
    dispatch(
      increaseAttemptCounter({
        page: currentPage,
        cardId: card.id,
      })
    )
    dispatch(
      setGuide({
        page: currentPage,
        cardId: card.id,
        condition: correct,
      })
    )
  }

  const handleDataChange = (key: string, value: string) => {
    if (card.condition !== true) {
      dispatch(
        setClozeAnswer({
          page: currentPage,
          cardId: card.id,
          key: key,
          data: value,
        })
      )
    }
  }

  return (
    <div id={card.region} className="col-md-12">
      <div
        className={`panel ${card.type} ${card.codename} ${
          card.condition === true
            ? 'panel-success'
            : card.condition === 'skip'
            ? 'panel-danger'
            : card.condition === false
            ? 'panel-warning'
            : 'panel-default'
        }`}
      >
        {card.title ? (
          <div className="panel-heading">
            <h3 className="panel-title">{card.title}</h3>
          </div>
        ) : (
          <div></div>
        )}
        <div className="panel-body">
          <div className="card-content">
            {card.content ? (
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: card.content,
                }}
              ></div>
            ) : (
              <div></div>
            )}
            <div className="question-text">
              <ModalInstructionsCloze></ModalInstructionsCloze>
              <div
                dangerouslySetInnerHTML={{
                  __html: card.question_data.question_text,
                }}
              ></div>
            </div>
          </div>
          <div className="alert alert-warning sticky-top">
            <strong>{t('cloze_card.terms')}: </strong>
            {card.question_data.keywords &&
              card.question_data.keywords.map(({ keyword, used }, i) => {
                return (
                  <>
                    <span
                      key={keyword}
                      className={`${used ? 'used' : 'unused'} word`}
                    >
                      {keyword}
                    </span>
                    {i < card.question_data.keywords.length - 1 && (
                      <span>, </span>
                    )}
                  </>
                )
              })}
          </div>
          <div className="form form-inline form-inline-no-flex solution">
            <p>
              {card.question_data.elements.map(e => {
                if (e.type === 'input') {
                  const listed = card.question_data.keywords
                    .map(({ keyword }) => {
                      return keyword
                    })
                    .includes(e.data)
                  return (
                    <CustomInput
                      status={e.status}
                      listed={listed}
                      condition={card.condition}
                      key={e.key}
                      validText={e.key}
                      value={e.data}
                      handler={handleDataChange}
                    ></CustomInput>
                  )
                } else {
                  return (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: e.data,
                      }}
                    ></span>
                  )
                }
              })}
            </p>
          </div>
        </div>
        <div className="panel-footer">
          <button
            className="btn btn-primary btn-attempt"
            onClick={() => {
              checkAnswer()
            }}
            disabled={card.condition === true || card.condition === 'skip'}
            hidden={card.condition === true || card.condition === 'skip'}
          >
            <FontAwesomeIcon icon={'check'} /> {t('question_status.check')}
          </button>
          <button
            className="btn btn-default btn-skip"
            onClick={skip}
            disabled={card.condition === true || card.condition === 'skip'}
            hidden={card.condition === true || card.condition === 'skip'}
          >
            <FontAwesomeIcon icon={'share'} /> {t('question_status.skip')}
          </button>
          <small className="pull-right text-right">
            {card.condition === true ? (
              <div>
                <FontAwesomeIcon icon={'check'} /> {t('question_status.right')}
              </div>
            ) : card.condition === false ? (
              <div>
                <FontAwesomeIcon icon={'undo'} /> {t('question_status.wrong')}
              </div>
            ) : card.condition === 'skip' ? (
              <div>
                <FontAwesomeIcon icon={'undo'} /> {t('question_status.skipped')}
              </div>
            ) : (
              <div>
                <FontAwesomeIcon icon={'minus'} />{' '}
                {t('question_status.unedited')}
              </div>
            )}
            {t('question_status.attempts')}: {card.number_of_attempts}
          </small>
          <span className="clearfix"></span>
        </div>
      </div>
    </div>
  )
}

export default ClozeCard
