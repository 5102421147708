import { Form } from 'react-bootstrap'
import React from 'react'

const CustomSelect = ({
  status,
  condition,
  id,
  choices,
  value,
  handler,
}: {
  condition: boolean | string | undefined
  id: string
  choices: string[]
  value: string
  handler: Function
  status: undefined | boolean
}) => {
  return (
    <Form.Control
      as="select"
      className={`${
        condition === true || status === true
          ? 'alert-success'
          : condition === 'skip'
          ? 'alert-danger'
          : ''
      }`}
      disabled={condition === true || condition === 'skip' || status === true}
      onChange={event => {
        handler(id, event.target.value)
      }}
      value={value}
    >
      {choices.map((value: string, index: number) => {
        return <option key={index}>{value}</option>
      })}
    </Form.Control>
  )
}

export default CustomSelect
