import {
  sendPageLoaded,
  sendQuizFinished,
} from '../../../store/analytics/analytics'
import { useDispatch, useSelector } from 'react-redux'

import QuizCard from './QuizCard'
import React from 'react'
import { RootState } from '../../../store'
import StageNavigation from '../StageNavigation'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const QuizStage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  //const { pageId } = useParams()
  const { eventCode, codename } = useSelector((state: RootState) => state.misc)

  const {
    data: { pages },
  } = useSelector((state: RootState) => state.quiz)
  const {
    currentPage,
    data: { score },
  } = useSelector((state: RootState) => state.quiz)

  const page = pages[currentPage]

  useEffect(() => {
    history.push(`/${eventCode}/${codename}/${currentPage}`)
  }, [currentPage, eventCode, codename, history])

  //this only runs once
  useEffect(() => {
    dispatch(sendPageLoaded(page))
    if (page.is_last) {
      dispatch(sendQuizFinished(score))
    }
  }, [currentPage])

  return (
    <div className="quiz-page" style={{ marginBottom: 50 }}>
      <div id="cards">
        {page.cards.map(card => {
          // Return the element. Also pass key
          return <QuizCard card={card} key={card.id}></QuizCard>
        })}
        <StageNavigation
          backward={{
            text: 'Zurück',
            handler: () => {
              console.log('zurück')
            },
          }}
          forward={{
            text: 'Weiter',
            handler: () => {
              console.log('vorwärts')
            },
          }}
        />
      </div>
    </div>
  )
}

export default QuizStage
