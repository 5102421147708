import { Container, Spinner } from 'react-bootstrap'
import { Route, Switch } from 'react-router-dom'
import { isTabletOrSmallDesktopQuery, isTouchDevice } from './utils'
import store, { persistor } from './store'

import Footer from './components/shared/Footer'
import InitialData from './components/shared/InitialData'
import MainNavbar from './components/shared/MainNavbar'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import QuizGuide from './components/shared/QuizGuide'
import QuizStage from './components/shared/quiz/QuizStage'
import React from 'react'
import { Router } from 'react-router'
import StageIndicator from './components/shared/StageIndicator'
import StartPage from './components/shared/start/StartPage'
import { createBrowserHistory } from 'history'
import { useMediaQuery } from 'react-responsive'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export let browserHistory: any

const QuizApp = () => {
  browserHistory = createBrowserHistory({ basename: `/` })
  return (
    <Provider store={store}>
      <PersistGate
        loading={<Spinner variant="primary" animation="grow" />}
        persistor={persistor}
      >
        <Router history={browserHistory}>
          <Route>
            <InitialData>
              <MainNavbar />
              <StageIndicator />
              <div
                className="sticky-top"
                style={{
                  width: useMediaQuery(isTabletOrSmallDesktopQuery)
                    ? ''
                    : '1300px',
                }}
              >
                <QuizGuide />
              </div>
              <Container>
                <Switch>
                  <Route path={'/:eventCode/:codename'} component={QuizStage} />
                  <Route path={'/'} component={StartPage} />
                </Switch>
              </Container>
              <Footer />
            </InitialData>
          </Route>
        </Router>
      </PersistGate>
    </Provider>
  )
}

export default QuizApp
