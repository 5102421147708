import { Button, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

import { DebounceInput } from 'react-debounce-input'
import Footer from '../Footer'
import { Form } from 'react-bootstrap'
import { ISchool } from '../../../store/quiz/interfaces'
import React from 'react'
import { RootState } from '../../../store'
import { setEvent } from '../../../store/misc/misc'
import { setSchool as setSchoolData } from '../../../store/quiz/quiz'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface StartpageProps {
  initialEventCode?: string
  initialCodename?: string
}

const StartPage = ({ initialEventCode, initialCodename }: StartpageProps) => {
  const [text, setText] = useState('')
  const [school, setSchool] = useState({} as ISchool)
  const [classs, setClass] = useState('')
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const { eventCode, codename } = useSelector((state: RootState) => state.misc)
  const { currentPage, school_choices, loaded, done } = useSelector(
    (state: RootState) => state.quiz
  )

  if (eventCode !== '' && codename !== '' && loaded && done) {
    history.push(`/${eventCode}/${codename}/${currentPage}`)
  }

  useEffect(() => {
    if (initialEventCode && initialCodename) {
      setText(`${initialEventCode}.${initialCodename}`)
      dispatch(
        setEvent({
          eventCode: initialEventCode,
          codename: initialCodename,
        })
      )
    }
  }, [])

  return (
    <>
      <div className="container">
        <div>
          <h1 style={{ color: '#10b4f1' }}>{t('start_page.title')}</h1>
          <p>{t('start_page.introduction')}</p>
          <p>{t('start_page.description')}</p>
          <h4 style={{ color: '#10b4f1' }}>{t('start_page.code_input')}:</h4>
          <DebounceInput
            minLength={2}
            debounceTimeout={300}
            style={{
              padding: '1rem',
              marginBottom: '1%',
              width: '100%',
              outline: 'none',
            }}
            value={text}
            valid={loaded}
            className="textinput"
            placeholder="Code"
            onChange={e => {
              setText(e.target.value)
              const eventCode = e.target.value.split('.')[0]
              const codename = e.target.value.split('.')[1]
              if (eventCode && codename) {
                dispatch(
                  setEvent({
                    eventCode: e.target.value.split('.')[0],
                    codename: e.target.value.split('.')[1],
                  })
                )
              }
            }}
          />
          {loaded && school_choices && school_choices.length > 0 ? (
            <div>
              <Row
                style={{
                  padding: '1rem',
                  marginBottom: '1%',
                  width: '100%',
                }}
              >
                <h4 style={{ color: '#10b4f1' }}>
                  {t('start_page.school_choice')}:
                </h4>
                <Form.Control
                  as="select"
                  onChange={e => {
                    setSchool(
                      school_choices.find(
                        s => s.name === e.currentTarget.value
                      ) || ({} as ISchool)
                    )
                  }}
                >
                  <option value={''}>---</option>
                  {school_choices.map(s => (
                    <option value={s.name} key={s.name}>
                      {s.name}
                    </option>
                  ))}
                  <option value={'Schule nicht in der Liste'}>
                    {t('start_page.school_not_listed')}
                  </option>
                </Form.Control>
              </Row>
              {school && school.classes && school.classes.length > 0 ? (
                <>
                  <Row
                    style={{
                      padding: '1rem',
                      marginBottom: '1%',
                      width: '100%',
                    }}
                  >
                    <h4 style={{ color: '#10b4f1' }}>
                      {t('start_page.class_choice')}:
                    </h4>
                    <Form.Control
                      as="select"
                      onChange={e => setClass(e.currentTarget.value)}
                    >
                      <option value={''}>---</option>
                      {school.classes.map(c => (
                        <option value={c} key={c}>
                          {c}
                        </option>
                      ))}
                      <option value={'Klasse nicht in der Liste'}>
                        {t('start_page.class_not_listed')}
                      </option>
                    </Form.Control>
                  </Row>
                  {classs && (
                    <Row>
                      <Button
                        style={{
                          color: 'white',
                          background: '#10b4f1',
                          border: '#10b4f1',
                          width: '25',
                        }}
                        onClick={() =>
                          dispatch(
                            setSchoolData({
                              school: school.name,
                              class: classs,
                            })
                          )
                        }
                      >
                        {t('start_page.start')}
                      </Button>
                    </Row>
                  )}
                </>
              ) : (
                <Row>
                  <Button
                    style={{
                      color: 'white',
                      background: '#10b4f1',
                      border: '#10b4f1',
                      width: '25',
                    }}
                    onClick={() =>
                      dispatch(
                        setSchoolData({ school: school.name, class: classs })
                      )
                    }
                  >
                    {t('start_page.start')}
                  </Button>
                </Row>
              )}
            </div>
          ) : loaded ? (
            <Row>
              <Button
                disabled={!done}
                style={{
                  color: 'white',
                  background: '#10b4f1',
                  border: '#10b4f1',
                  width: '25',
                }}
                onClick={() =>
                  dispatch(
                    setSchoolData({ school: school.name, class: classs })
                  )
                }
              >
                {t('start_page.start')}
              </Button>
            </Row>
          ) : (
            <Row>
              <Button
                style={{
                  color: 'white',
                  background: '#10b4f1',
                  border: '#10b4f1',
                  width: '25',
                }}
                disabled={true}
              >
                {t('start_page.invalid_code')}
              </Button>
            </Row>
          )}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default StartPage
