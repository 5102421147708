import { Form } from 'react-bootstrap'
import React from 'react'

const CustomTextAnswer = ({
  condition,
  value,
  handler,
}: {
  condition: boolean | string | undefined
  value: string
  handler: Function
}) => {
  return (
    <Form.Control
      id="input"
      as="textarea"
      disabled={condition === true}
      className="form-control"
      onChange={event => {
        handler(event.target.value)
      }}
      value={value}
    ></Form.Control>
  )
}

export default CustomTextAnswer
