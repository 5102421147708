import React, { useEffect } from 'react'
import { fetchQuiz, fetchSchools } from '../../store/quiz/quiz'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../store'
import StartPage from './start/StartPage'
import { createAction } from '@reduxjs/toolkit'
import { fetchTheme } from '../../store/theme/theme'
import { matchPath } from 'react-router'

const InitialData = (props: { children?: any }) => {
  const { baseUrl, eventCode, codename } = useSelector(
    (state: RootState) => state.misc
  )
  const dispatch = useDispatch()
  const { children } = props

  const { done } = useSelector((state: RootState) => state.quiz)
  const { theme_key } = useSelector((state: RootState) => state.theme)
  const KSKTheme = React.lazy(() => import('../themes/KSKTheme'))
  const VobaTheme = React.lazy(() => import('../themes/VobaTheme'))
  const CornelsenTheme = React.lazy(() => import('../themes/CornelsenTheme'))

  if (window.location.pathname === '' || window.location.pathname === '/') {
    const resetAction = createAction('reset')
    dispatch(resetAction())
    window.location.replace('/start')
  }

  const match = matchPath(window.location.pathname, {
    path: '/:event/:code/0',
    exact: true,
    strict: true,
  })

  useEffect(() => {
    dispatch(fetchQuiz())
    dispatch(fetchTheme())
    dispatch(fetchSchools())
  }, [dispatch, baseUrl, eventCode, codename, theme_key])

  if (done) {
    return (
      <>
        <React.Suspense fallback={<></>}>
          {theme_key === 'voba' && <VobaTheme />}
          {theme_key === 'new_ksk' && <KSKTheme />}
          {theme_key === 'cornelsen' && <CornelsenTheme />}
        </React.Suspense>
        {children}
      </>
    )
  } else {
    if (match) {
      return (
        <StartPage
          //@ts-ignore
          initialEventCode={match.params.event}
          //@ts-ignore
          initialCodename={match.params.code}
        />
      )
    }
    return <StartPage />
  }
}

export default InitialData
