import { AppThunk, RootState } from '..'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { IPage } from '../quiz/interfaces'
import getStoredState from 'redux-persist/es/getStoredState'
import moment from 'moment'

const analyticsUrl = process.env.REACT_APP_ANALYTICS_URL || ''

const misc = createSlice({
  name: 'analytics',
  initialState: {
    analyticsUrl: analyticsUrl,
    user: 'user-' + String(Math.random()).replace('0.', ''),
    lastPage: {} as IPage,
    lastPageLoad: {} as moment.Moment,
  },
  reducers: {
    setLastPage(state, action) {
      state.lastPage = action.payload
    },
    setLastPageLoad(state, action) {
      state.lastPageLoad = action.payload
    },
  },
})

const sendAnalytics = async (data: any, state: RootState) => {
  data = {
    ...data,
    user: state.analytics.user,
    quiz_name: state.misc.codename,
    event_id: state.misc.eventCode,
    school: state.quiz.school,
    school_class: state.quiz.class,
  }
  fetch(analyticsUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const { setLastPage, setLastPageLoad } = misc.actions
export default misc.reducer

export const sendPageLoaded = (page: IPage): AppThunk => async (
  dispatch,
  getState
) => {
  const { lastPage, lastPageLoad } = getState().analytics
  let viewDuration: number
  console.log('[analytics] page loaded', page)
  if (lastPage.id) {
    console.log('[analytics] last page loaded was', lastPage)
    viewDuration = moment().diff(lastPageLoad, 'seconds')
    console.log('[analytics] was viewed for', viewDuration, 'seconds')
  } else {
    viewDuration = 0
  }
  await sendAnalytics(
    {
      event_type: 'page:loaded',
      page_id: lastPage ? lastPage.id : undefined,
      duration: viewDuration,
    },
    getState()
  )
  dispatch(setLastPageLoad(moment().format()))
  dispatch(setLastPage(page))
}

export const sendQuizFinished = (grade: string): AppThunk => async (
  dispatch,
  getState
) => {
  await sendAnalytics(
    {
      event_type: 'quiz:finished',
      grade,
    },
    getState()
  )
}

export const sendCardAttempted = (status: string): AppThunk => async (
  dispatch,
  getState
) => {
  await sendAnalytics(
    {
      event_type: 'card:attempted',
      status,
    },
    getState()
  )
}

export const sendCertificateLoaded = (): AppThunk => async (
  dispatch,
  getState
) => {
  await sendAnalytics(
    {
      event_type: 'quiz:certificateLoaded',
    },
    getState()
  )
}
