import { stages } from './routes'

const getStageInformation = (currentKey: string) => {
  const currentIndex = stages.findIndex(stage => stage.key === currentKey)
  return {
    currentIndex: currentIndex,
    previous: stages[currentIndex - 1],
    next: stages[currentIndex + 1],
  }
}

// small breakpoint for small smartphones
export const isSmallMobileQuery = { query: '(max-width: 320px)' }

// breakpoint for smartphones only
export const isMobileQuery = { query: '(max-width: 575px)' }

// medium breakpoint for small tablets or smartphones
export const isTabletOrMobileQuery = { query: '(max-width: 834px)' }

// large breakpoint for bigger tablets and desktops
export const isTabletOrSmallDesktopQuery = { query: '(max-width: 1200px)' }

// extra large breakpoint for larger desktops and above
export const isLargeDesktopOrTabletQuery = { query: '(max-width: 1366px)' }

export { getStageInformation }

//@ts-ignore
export const shuffle = (arr: any[]): any[] => {
  let j, x, index
  for (index = arr.length - 1; index > 0; index--) {
    j = Math.floor(Math.random() * (index + 1))
    x = arr[index]
    arr[index] = arr[j]
    arr[j] = x
  }
  return arr
}

export const isTouchDevice = () => {
  if ('ontouchstart' in window) {
    return true
  }
  return false
}
