import {
  increaseAttemptCounter,
  setCardCondition,
  setCharAnswer,
  setCharStatus,
  setGuide,
  skipCharmap,
} from '../../../store/quiz/quiz'
import { useDispatch, useSelector } from 'react-redux'

import CustomCharInput from './CustomCharInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ICard } from '../../../store/quiz/interfaces'
import ModalInstructionsCharmap from './ModalInstructionsCharmap'
import React from 'react'
import { RootState } from '../../../store'
import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const CharmapCard = ({ card }: { card: ICard }) => {
  const { currentPage } = useSelector((state: RootState) => state.quiz)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const compareAnswers = () => {
    const answers_array = card.question_data.char
      .filter(e => e.key !== '')
      .map(e => {
        if (e.data === e.char) {
          dispatch(
            setCharStatus({
              page: currentPage,
              cardId: card.id,
              status: e.data === e.char,
              key: e.key,
            })
          )
        }
        return e.data === e.char
      })
    if (answers_array.includes(false)) {
      return false
    } else if (answers_array.every(Boolean)) {
      return true
    }
  }

  const skip = () => {
    dispatch(
      setCardCondition({
        page: currentPage,
        cardId: card.id,
        condition: 'skip',
      })
    )
    dispatch(
      setGuide({
        page: currentPage,
        cardId: card.id,
        condition: 'skip',
      })
    )
    dispatch(
      skipCharmap({
        page: currentPage,
        cardId: card.id,
      })
    )
  }

  const checkAnswer = () => {
    const correct = compareAnswers()
    console.log(card.question_data.char)
    dispatch(
      setCardCondition({
        page: currentPage,
        cardId: card.id,
        condition: correct,
      })
    )
    dispatch(
      increaseAttemptCounter({
        page: currentPage,
        cardId: card.id,
      })
    )
    dispatch(
      setGuide({
        page: currentPage,
        cardId: card.id,
        condition: correct,
      })
    )
  }

  const handleFocusChange = (e: { target: any }) => {
    const target = e.target
    const maxLength = parseInt(target.attributes['maxLength'].value, 10)
    const myLength = target.value.length
    let previous: any

    // Move to next field
    if (myLength >= maxLength) {
      const next_input =
        target.parentNode.parentNode.parentNode.parentNode.nextElementSibling
          .lastChild.lastChild.lastChild.lastChild

      const next_input_type = next_input
        .toString()
        .replace(']', '')
        .split(' ')[1]

      if (next_input && next_input_type === 'HTMLInputElement') {
        next_input.focus()
      }
    }
    // Move to previous field if empty (user pressed backspace)
    else if (myLength === 0) {
      const previous_table =
        target.parentNode.parentNode.parentNode.parentNode
          .previousElementSibling
      const previous_table_type = previous_table
        .toString()
        .replace(']', '')
        .split(' ')[1]

      if (previous_table && previous_table_type === 'HTMLTableElement') {
        previous = previous_table.lastChild.lastChild.lastChild.lastChild
      }

      if (previous) {
        previous.focus()
      }
    }
  }

  const handleDataChange = (key: string, value: string) => {
    if (card.condition !== true) {
      dispatch(
        setCharAnswer({
          page: currentPage,
          cardId: card.id,
          key: key,
          data: value,
        })
      )
    }
  }

  return (
    <div id={card.region} className="col-md-12">
      <div
        className={`panel ${card.type} ${card.codename} ${
          card.condition === true
            ? 'panel-success'
            : card.condition === 'skip'
            ? 'panel-danger'
            : card.condition === false
            ? 'panel-warning'
            : 'panel-default'
        }`}
      >
        {card.title ? (
          <div className="panel-heading">
            <h3 className="panel-title">{card.title}</h3>
          </div>
        ) : (
          <div></div>
        )}
        <div className="panel-body">
          <div className="card-content">
            {card.content ? (
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: card.content,
                }}
              ></div>
            ) : (
              <div></div>
            )}
            <div className="question-text">
              <ModalInstructionsCharmap></ModalInstructionsCharmap>
              <div
                dangerouslySetInnerHTML={{
                  __html: card.question_data.question_text,
                }}
              ></div>
            </div>
          </div>

          <div className="table-responsive sticky-top">
            <table
              className="table alphabet table-bordered table-striped"
              style={{ backgroundColor: 'white' }}
            >
              <tbody>
                <tr>
                  <td>1</td>
                  <td>2</td>
                  <td>3</td>
                  <td>4</td>
                  <td>5</td>
                  <td>6</td>
                  <td>7</td>
                  <td>8</td>
                  <td>9</td>
                  <td>10</td>
                  <td>11</td>
                  <td>12</td>
                  <td>13</td>
                  <td>14</td>
                  <td>15</td>
                  <td>16</td>
                  <td>17</td>
                  <td>18</td>
                  <td>19</td>
                  <td>20</td>
                  <td>21</td>
                  <td>22</td>
                  <td>23</td>
                  <td>24</td>
                  <td>25</td>
                  <td>26</td>
                  <td>27</td>
                  <td>28</td>
                  <td>29</td>
                  <td>30</td>
                </tr>
                <tr>
                  <td>A</td>
                  <td>B</td>
                  <td>C</td>
                  <td>D</td>
                  <td>E</td>
                  <td>F</td>
                  <td>G</td>
                  <td>H</td>
                  <td>I</td>
                  <td>J</td>
                  <td>K</td>
                  <td>L</td>
                  <td>M</td>
                  <td>N</td>
                  <td>O</td>
                  <td>P</td>
                  <td>Q</td>
                  <td>R</td>
                  <td>S</td>
                  <td>T</td>
                  <td>U</td>
                  <td>V</td>
                  <td>W</td>
                  <td>X</td>
                  <td>Y</td>
                  <td>Z</td>
                  <td>Ä</td>
                  <td>Ö</td>
                  <td>Ü</td>
                  <td>ß</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="solution">
            {card.question_data.char.map(e => {
              if (e.type === 'input') {
                return (
                  <table
                    className="table-charmap"
                    style={{ width: '30px', whiteSpace: 'nowrap' }}
                  >
                    <tbody>
                      <tr>
                        <td>
                          {e.char.toUpperCase() === 'A' ? (
                            <div>1</div>
                          ) : e.char.toUpperCase() === 'B' ? (
                            <div>2</div>
                          ) : e.char.toUpperCase() === 'C' ? (
                            <div>3</div>
                          ) : e.char.toUpperCase() === 'D' ? (
                            <div>4</div>
                          ) : e.char.toUpperCase() === 'E' ? (
                            <div>5</div>
                          ) : e.char.toUpperCase() === 'F' ? (
                            <div>6</div>
                          ) : e.char.toUpperCase() === 'G' ? (
                            <div>7</div>
                          ) : e.char.toUpperCase() === 'H' ? (
                            <div>8</div>
                          ) : e.char.toUpperCase() === 'I' ? (
                            <div>9</div>
                          ) : e.char.toUpperCase() === 'J' ? (
                            <div>10</div>
                          ) : e.char.toUpperCase() === 'K' ? (
                            <div>11</div>
                          ) : e.char.toUpperCase() === 'L' ? (
                            <div>12</div>
                          ) : e.char.toUpperCase() === 'M' ? (
                            <div>13</div>
                          ) : e.char.toUpperCase() === 'N' ? (
                            <div>14</div>
                          ) : e.char.toUpperCase() === 'O' ? (
                            <div>15</div>
                          ) : e.char.toUpperCase() === 'P' ? (
                            <div>16</div>
                          ) : e.char.toUpperCase() === 'Q' ? (
                            <div>17</div>
                          ) : e.char.toUpperCase() === 'R' ? (
                            <div>18</div>
                          ) : e.char.toUpperCase() === 'S' ? (
                            <div>19</div>
                          ) : e.char.toUpperCase() === 'T' ? (
                            <div>20</div>
                          ) : e.char.toUpperCase() === 'U' ? (
                            <div>21</div>
                          ) : e.char.toUpperCase() === 'V' ? (
                            <div>22</div>
                          ) : e.char.toUpperCase() === 'W' ? (
                            <div>23</div>
                          ) : e.char.toUpperCase() === 'X' ? (
                            <div>24</div>
                          ) : e.char.toUpperCase() === 'Y' ? (
                            <div>25</div>
                          ) : e.char.toUpperCase() === 'Z' ? (
                            <div>26</div>
                          ) : e.char.toUpperCase() === 'Ä' ? (
                            <div>27</div>
                          ) : e.char.toUpperCase() === 'Ö' ? (
                            <div>28</div>
                          ) : e.char.toUpperCase() === 'Ü' ? (
                            <div>29</div>
                          ) : e.char.toUpperCase() === 'ß' ? (
                            <div>30</div>
                          ) : (
                            <div>O=O</div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td id={e.key}>
                          <CustomCharInput
                            status={e.status}
                            condition={card.condition}
                            answerkey={e.key}
                            key={e.key}
                            value={e.data}
                            handler={handleDataChange}
                            focus={handleFocusChange}
                          ></CustomCharInput>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )
              } else {
                return (
                  <>
                    {' '}
                    <table
                      className="table-charmap"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <tbody>
                        <tr>
                          <td>{e.data}</td>
                        </tr>
                      </tbody>
                    </table>
                    <br></br>
                  </>
                )
              }
            })}
          </div>
        </div>
        <div className="panel-footer">
          <button
            className="btn btn-primary btn-attempt"
            onClick={() => {
              checkAnswer()
            }}
            disabled={card.condition === true || card.condition === 'skip'}
            hidden={card.condition === true || card.condition === 'skip'}
          >
            <FontAwesomeIcon icon={'check'} /> {t('question_status.check')}
          </button>
          <button
            className="btn btn-default btn-skip"
            onClick={skip}
            disabled={card.condition === true || card.condition === 'skip'}
            hidden={card.condition === true || card.condition === 'skip'}
          >
            <FontAwesomeIcon icon={'share'} /> {t('question_status.skip')}
          </button>
          <small className="pull-right text-right">
            {card.condition === true ? (
              <div>
                <FontAwesomeIcon icon={'check'} /> {t('question_status.right')}
              </div>
            ) : card.condition === false ? (
              <div>
                <FontAwesomeIcon icon={'undo'} /> {t('question_status.wrong')}
              </div>
            ) : card.condition === 'skip' ? (
              <div>
                <FontAwesomeIcon icon={'undo'} /> {t('question_status.skipped')}
              </div>
            ) : (
              <div>
                <FontAwesomeIcon icon={'minus'} />{' '}
                {t('question_status.unedited')}
              </div>
            )}
            {t('question_status.attempts')}: {card.number_of_attempts}
          </small>
          <span className="clearfix"></span>
        </div>
      </div>
    </div>
  )
}

export default CharmapCard
