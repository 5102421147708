import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationDE from '../src/assets/locales/de/translation.json'
import translationEN from '../src/assets/locales/en/translation.json'
import translationES from '../src/assets/locales/es/translation.json'
import translationFR from '../src/assets/locales/fr/translation.json'

const fallbackLng = ['de']
const availableLanguages = ['de', 'en', 'es', 'fr']

const resources = {
  de: {
    translation: translationDE,
  },
  en: {
    translation: translationEN,
  },

  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true,
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
