import {
  increaseAttemptCounter,
  setCardCondition,
  setClozeChoiceCheckedChoice,
  setClozeChoiceData,
  setClozeChoiceStatus,
  setGuide,
  skipClozeChoice,
} from '../../../store/quiz/quiz'
import { useDispatch, useSelector } from 'react-redux'

import CustomSelect from './CustomSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ICard } from '../../../store/quiz/interfaces'
import ModalInstructionsChoice from './ModalInstructionsChoice'
import ModalInstructionsClozeChoice from './ModalInstructionsClozeChoice'
import React from 'react'
import { RootState } from '../../../store'
import { useTranslation } from 'react-i18next'

const ClozeChoiceCard = ({ card }: { card: ICard }) => {
  const { currentPage } = useSelector((state: RootState) => state.quiz)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleDataChange = (key: string, value: string) => {
    if (card.condition !== true) {
      dispatch(
        setClozeChoiceData({
          page: currentPage,
          cardId: card.id,
          key: key,
          data: value,
        })
      )
      dispatch(
        setClozeChoiceCheckedChoice({
          page: currentPage,
          cardId: card.id,
          key: key,
          data: value,
        })
      )
    }
  }

  const skip = () => {
    dispatch(
      setCardCondition({
        page: currentPage,
        cardId: card.id,
        condition: 'skip',
      })
    )
    dispatch(
      setGuide({
        page: currentPage,
        cardId: card.id,
        condition: 'skip',
      })
    )
    dispatch(
      skipClozeChoice({
        page: currentPage,
        cardId: card.id,
      })
    )
  }

  const checkAnswer = () => {
    const answers = card.question_data.choices_clozechoice.filter(
      c => c.type === 'select'
    )
    const correct_answered = answers.filter(
      c => c.data === c.correct_answers[0]
    )

    correct_answered.map(e => {
      dispatch(
        setClozeChoiceStatus({
          page: currentPage,
          cardId: card.id,
          status: true,
          key: e.key,
        })
      )
    })

    const correct = correct_answered.length === answers.length
    dispatch(
      setCardCondition({
        page: currentPage,
        cardId: card.id,
        condition: correct,
      })
    )
    dispatch(
      increaseAttemptCounter({
        page: currentPage,
        cardId: card.id,
      })
    )
    dispatch(
      setGuide({
        page: currentPage,
        cardId: card.id,
        condition: correct,
      })
    )
  }

  return (
    <div id={card.region} className="col-md-12">
      <div
        className={`panel ${card.type} ${card.codename} ${
          card.condition === true
            ? 'panel-success'
            : card.condition === 'skip'
            ? 'panel-danger'
            : card.condition === false
            ? 'panel-warning'
            : 'panel-default'
        }`}
      >
        {card.title ? (
          <div className="panel-heading">
            <h3 className="panel-title">{card.title}</h3>
          </div>
        ) : (
          <div></div>
        )}
        <div className="panel-body">
          <div className="card-content">
            {card.content ? (
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: card.content,
                }}
              ></div>
            ) : (
              <div></div>
            )}
            <div className="question-text">
              {card.question_data.choice_view === 'select' ? (
                <ModalInstructionsClozeChoice></ModalInstructionsClozeChoice>
              ) : (
                <ModalInstructionsChoice></ModalInstructionsChoice>
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html: card.question_data.question_text,
                }}
              ></div>
            </div>
          </div>
          <div className="form form-inline form-inline-no-flex solution">
            <p>
              {card.question_data.choice_view === 'select'
                ? card.question_data.choices_clozechoice.map(e => {
                    if (e.type === 'select') {
                      return (
                        <CustomSelect
                          condition={card.condition}
                          id={e.key}
                          choices={e.choices}
                          value={e.data}
                          handler={handleDataChange}
                          status={e.status}
                        ></CustomSelect>
                      )
                    } else {
                      return (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: e.data,
                          }}
                        ></span>
                      )
                    }
                  })
                : card.question_data.choices_clozechoice.map(e => {
                    if (e.type === 'select') {
                      return (
                        <div className="clozechoices">
                          {e.choices.map((value: string) => {
                            return (
                              <>
                                <div className="radio">
                                  <p style={{ margin: '5px' }}>
                                    <label
                                      className={`${
                                        (card.condition === true ||
                                          card.condition === 'skip' ||
                                          e.status === true) &&
                                        e.checked_choice === value
                                          ? 'correct'
                                          : (card.condition === true ||
                                              card.condition === 'skip') &&
                                            e.checked_choice !== value
                                          ? 'wrong'
                                          : ''
                                      }`}
                                    >
                                      <input
                                        checked={e.checked_choice === value}
                                        type="radio"
                                        disabled={
                                          card.condition === true ||
                                          card.condition === 'skip'
                                        }
                                        onChange={() => {
                                          handleDataChange(e.key, value)
                                        }}
                                      />
                                      {value}
                                    </label>
                                  </p>
                                </div>
                              </>
                            )
                          })}
                        </div>
                      )
                    } else {
                      return (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: e.data,
                          }}
                        ></span>
                      )
                    }
                  })}
            </p>
          </div>
        </div>
        <div className="panel-footer">
          <button
            className="btn btn-primary btn-attempt"
            onClick={() => {
              checkAnswer()
            }}
            disabled={card.condition === true || card.condition === 'skip'}
            hidden={card.condition === true || card.condition === 'skip'}
          >
            <FontAwesomeIcon icon={'check'} /> {t('question_status.check')}
          </button>
          <button
            className="btn btn-default btn-skip"
            onClick={skip}
            disabled={card.condition === true || card.condition === 'skip'}
            hidden={card.condition === true || card.condition === 'skip'}
          >
            <FontAwesomeIcon icon={'share'} /> {t('question_status.skip')}
          </button>
          <small className="pull-right text-right">
            {card.condition === true ? (
              <div>
                <FontAwesomeIcon icon={'check'} /> {t('question_status.right')}
              </div>
            ) : card.condition === false ? (
              <div>
                <FontAwesomeIcon icon={'undo'} /> {t('question_status.wrong')}
              </div>
            ) : card.condition === 'skip' ? (
              <div>
                <FontAwesomeIcon icon={'undo'} /> {t('question_status.skipped')}
              </div>
            ) : (
              <div>
                <FontAwesomeIcon icon={'minus'} />{' '}
                {t('question_status.unedited')}
              </div>
            )}
            {t('question_status.attempts')}: {card.number_of_attempts}
          </small>
          <span className="clearfix"></span>
        </div>
      </div>
    </div>
  )
}
export default ClozeChoiceCard
