import { isMobileQuery, isTabletOrSmallDesktopQuery } from '../../utils'
import styled, { css, keyframes } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import { Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { RootState } from '../../store'
import { clearGuide } from '../../store/quiz/quiz'
import { shake } from 'react-animations'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

const shakeAnimation = keyframes`${shake}`
// Maybe replace shake with tada

const QuizGuide = () => {
  if (useMediaQuery(isMobileQuery)) {
    return <></>
  } else {
    return <DesktopQuizGuide />
  }
}

const StyledCol = styled(Col)`
  flex-grow: 0 !important;
  animation: ${props =>
    props.shake === 'true'
      ? css`
          ${shakeAnimation} 1s
        `
      : ''};
`

export default QuizGuide

const DesktopQuizGuide = () => {
  const { theme_key } = useSelector((state: RootState) => state.theme)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { guide, guide_status, guide_animated } = useSelector(
    (state: RootState) => state.quiz.data
  )
  const today = new Date()
  const minutes = () => {
    let min = ''
    if (today.getMinutes() < 10) {
      min = '0' + today.getMinutes()
    } else {
      min = '' + today.getMinutes()
    }
    return min
  }
  const time = today.getHours() + ':' + minutes()
  const isTabletOrSmallDesktop = useMediaQuery(isTabletOrSmallDesktopQuery)
  if (isTabletOrSmallDesktop && theme_key !== 'cornelsen') {
    return (
      <>
        <div
          className="table-container flex-column float-right"
          style={{ marginRight: '5%' }}
        >
          <StyledCol shake={guide_animated}>
            <div>
              <div style={{ position: 'relative' }}>
                <img
                  style={{ display: 'block' }}
                  src="/assets/images/guides/voba.png"
                  className="guide"
                  alt="Guide"
                  height="400px"
                ></img>

                <div
                  hidden={guide === ''}
                  style={{
                    padding: '5px',
                    paddingTop: '20px',
                    background: 'white',
                    position: 'absolute',
                    width: '190px',
                    height: '305px',
                    top: 43,
                    left: 10,
                    fontSize: '15px',
                    overflow: 'scroll',
                  }}
                >
                  <FontAwesomeIcon
                    icon={'times'}
                    className="float-right"
                    onClick={() => {
                      dispatch(clearGuide())
                    }}
                  />
                  {guide_status === true ? (
                    <div style={{ color: '#00C000' }}>
                      <FontAwesomeIcon icon={'check'} />{' '}
                      {t('quiz_guide.right_answer')}!
                    </div>
                  ) : guide_status === false ? (
                    <div style={{ color: '#FF8000' }}>
                      <FontAwesomeIcon icon={'undo'} />{' '}
                      {t('quiz_guide.try_again')}!
                    </div>
                  ) : (
                    <div style={{ color: '#3F98D4' }}>
                      <FontAwesomeIcon icon={'times'} />{' '}
                      {t('quiz_guide.aborted')}!
                    </div>
                  )}

                  {guide}
                </div>

                <div
                  className="smartphone-bar"
                  style={{
                    position: 'absolute',
                    top: 43,
                    left: 10,
                    fontSize: '10px',
                    width: '189px',
                    padding: '3px',
                    background: guide !== '' ? 'white' : '',
                  }}
                >
                  <FontAwesomeIcon icon={'signal'} />{' '}
                  {t('quiz_guide.connected')}
                  <FontAwesomeIcon
                    icon={'battery-three-quarters'}
                    className="float-right"
                  />
                </div>
                <div
                  hidden={guide !== ''}
                  className="smartphone-clock"
                  style={{
                    position: 'absolute',
                    top: 120,
                    left: 10,
                    fontSize: '35px',
                    width: '189px',
                    textAlign: 'center',
                  }}
                >
                  {time}
                </div>
              </div>
              <div
                className="bubble animated fadeOut"
                style={{ display: 'none' }}
              >
                <button type="button" className="close">
                  <span aria-hidden="true">x</span>
                </button>
                <div className="message"></div>
              </div>
            </div>
          </StyledCol>
        </div>
      </>
    )
  } else if (theme_key !== 'cornelsen') {
    return (
      <>
        <div className="table-container flex-column float-right">
          <StyledCol shake={guide_animated}>
            <div>
              <div style={{ position: 'relative' }}>
                <img
                  style={{ display: 'block' }}
                  src="/assets/images/guides/voba.png"
                  className="guide"
                  alt="Guide"
                  height="650px"
                ></img>

                <div
                  hidden={guide === ''}
                  style={{
                    padding: '5px',
                    paddingTop: '20px',
                    background: 'white',
                    position: 'absolute',
                    width: '308px',
                    height: '496px',
                    top: 70,
                    left: 16,
                    fontSize: '15px',
                    overflow: 'scroll',
                  }}
                >
                  <FontAwesomeIcon
                    icon={'times'}
                    className="float-right"
                    onClick={() => {
                      dispatch(clearGuide())
                    }}
                  />
                  {guide_status === true ? (
                    <div style={{ color: '#00C000' }}>
                      <FontAwesomeIcon icon={'check'} />{' '}
                      {t('quiz_guide.right_answer')}!
                    </div>
                  ) : guide_status === false ? (
                    <div style={{ color: '#FF8000' }}>
                      <FontAwesomeIcon icon={'undo'} />{' '}
                      {t('quiz_guide.try_again')}!
                    </div>
                  ) : (
                    <div style={{ color: '#3F98D4' }}>
                      <FontAwesomeIcon icon={'times'} />{' '}
                      {t('quiz_guide.aborted')}!
                    </div>
                  )}
                  {guide}
                </div>

                <div
                  className="smartphone-bar"
                  style={{
                    position: 'absolute',
                    top: 70,
                    left: 16,
                    fontSize: '10px',
                    width: '308px',
                    padding: '3px',
                    background: guide !== '' ? 'white' : '',
                  }}
                >
                  <FontAwesomeIcon icon={'signal'} />{' '}
                  {t('quiz_guide.connected')}
                  <FontAwesomeIcon
                    icon={'battery-three-quarters'}
                    className="float-right"
                  />
                </div>
                <div
                  hidden={guide !== ''}
                  className="smartphone-clock"
                  style={{
                    position: 'absolute',
                    top: 200,
                    left: 70,
                    fontSize: '35px',
                    width: '189px',
                    textAlign: 'center',
                  }}
                >
                  {time}
                </div>
              </div>
              <div
                className="bubble animated fadeOut"
                style={{ display: 'none' }}
              >
                <button type="button" className="close">
                  <span aria-hidden="true">x</span>
                </button>
                <div className="message"></div>
              </div>
            </div>
          </StyledCol>
        </div>
      </>
    )
  } else {
    return <div></div>
  }
}
