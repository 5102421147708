import {
  increaseAttemptCounter,
  setCardCondition,
  setGuide,
  setImagePointer,
} from '../../../store/quiz/quiz'
import { useDispatch, useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ICard } from '../../../store/quiz/interfaces'
import ModalInstructionsImagePointer from './ModalInstructionsImagePointer'
import React from 'react'
import { RootState } from '../../../store'
import { useTranslation } from 'react-i18next'

const ImagePointerCard = ({ card }: { card: ICard }) => {
  const { currentPage } = useSelector((state: RootState) => state.quiz)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const setAnswer = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    cardId: number
  ) => {
    if (card.condition !== true) {
      const image = document.getElementById(cardId.toString())
      if (image) {
        const imagePOS = image.getBoundingClientRect()
        const icon_left = e.clientX - imagePOS.x
        const relativeX = icon_left / imagePOS.width
        const icon_top = e.clientY - imagePOS.y
        const relativeY = icon_top / imagePOS.height
        const relativePos = [relativeX, relativeY]
        const icon_visible = 'visible'
        dispatch(
          setImagePointer({
            page: currentPage,
            cardId: card.id,
            relativePos: relativePos,
            icon_top: icon_top,
            icon_left: icon_left,
            icon_visible: icon_visible,
          })
        )
      }
    }
  }

  const skip = (cardId: number) => {
    dispatch(
      setCardCondition({
        page: currentPage,
        cardId: card.id,
        condition: 'skip',
      })
    )
    dispatch(
      setGuide({
        page: currentPage,
        cardId: card.id,
        condition: 'skip',
      })
    )
    const image = document.getElementById(cardId.toString())
    if (image) {
      const pos = card.question_data.imagePointer.correct_answer[0]
      const relativeX = (pos[2] + pos[0]) / 2
      const relativeY = (pos[3] + pos[1]) / 2
      const imagePOS = image.getBoundingClientRect()
      const relativePos = [relativeX, relativeY]
      const icon_left = relativeX * imagePOS.width
      const icon_top = relativeY * imagePOS.height

      const icon_visible = 'visible'
      dispatch(
        setImagePointer({
          page: currentPage,
          cardId: card.id,
          relativePos: relativePos,
          icon_top: icon_top,
          icon_left: icon_left,
          icon_visible: icon_visible,
        })
      )
    }
  }

  const checkAnswer = () => {
    const correct_answers = card.question_data.imagePointer.correct_answer[0]
    const answers = card.question_data.imagePointer.answer
    const correct =
      correct_answers[0] < answers[0] &&
      answers[0] < correct_answers[2] &&
      correct_answers[1] < answers[1] &&
      answers[1] < correct_answers[3]
    dispatch(
      setCardCondition({
        page: currentPage,
        cardId: card.id,
        condition: correct,
      })
    )
    dispatch(
      increaseAttemptCounter({
        page: currentPage,
        cardId: card.id,
      })
    )
    dispatch(
      setGuide({
        page: currentPage,
        cardId: card.id,
        condition: correct,
      })
    )
  }

  return (
    <div id={card.region} className="col-md-12">
      <div
        className={`panel ${card.type} ${card.codename} ${
          card.condition === true
            ? 'panel-success'
            : card.condition === 'skip'
            ? 'panel-danger'
            : card.condition === false
            ? 'panel-warning'
            : 'panel-default'
        }`}
      >
        {card.title ? (
          <div className="panel-heading">
            <h3 className="panel-title">{card.title}</h3>
          </div>
        ) : (
          <div></div>
        )}
        <div className="panel-body">
          <div className="card-content">
            {card.content ? (
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: card.content,
                }}
              ></div>
            ) : (
              <div></div>
            )}
            <div className="question-text">
              <ModalInstructionsImagePointer></ModalInstructionsImagePointer>
              <div
                dangerouslySetInnerHTML={{
                  __html: card.question_data.question_text,
                }}
              ></div>
            </div>
          </div>
          <div className="form form-inline form-inline-no-flex solution">
            <div style={{ position: 'relative' }}>
              <img
                style={{ display: 'block' }}
                id={`${card.id}`}
                src={card.question_data.image_url}
                className="img-thumbnail"
                alt="Imagepointer"
                onMouseDown={e => {
                  setAnswer(e, card.id)
                }}
              ></img>
              <FontAwesomeIcon
                icon={'mouse-pointer'}
                color="black"
                style={{
                  position: 'absolute',
                  top: card.question_data.imagePointer.icon_top,
                  left: card.question_data.imagePointer.icon_left,
                }}
                visibility={card.question_data.imagePointer.icon_visible}
              />
            </div>
          </div>
        </div>
        <div className="panel-footer">
          <button
            className="btn btn-primary btn-attempt"
            onClick={() => {
              checkAnswer()
            }}
            disabled={card.condition === true || card.condition === 'skip'}
            hidden={card.condition === true || card.condition === 'skip'}
          >
            <FontAwesomeIcon icon={'check'} /> {t('question_status.check')}
          </button>
          <button
            className="btn btn-default btn-skip"
            disabled={card.condition === true || card.condition === 'skip'}
            hidden={card.condition === true || card.condition === 'skip'}
            onClick={() => {
              skip(card.id)
            }}
          >
            <FontAwesomeIcon icon={'share'} /> {t('question_status.skip')}
          </button>
          <small className="pull-right text-right">
            {card.condition === true ? (
              <div>
                <FontAwesomeIcon icon={'check'} /> {t('question_status.right')}
              </div>
            ) : card.condition === false ? (
              <div>
                <FontAwesomeIcon icon={'undo'} /> {t('question_status.wrong')}
              </div>
            ) : card.condition === 'skip' ? (
              <div>
                <FontAwesomeIcon icon={'undo'} /> {t('question_status.skipped')}
              </div>
            ) : (
              <div>
                <FontAwesomeIcon icon={'minus'} />{' '}
                {t('question_status.unedited')}
              </div>
            )}
            {t('question_status.attempts')}: {card.number_of_attempts}
          </small>
          <span className="clearfix"></span>
        </div>
      </div>
    </div>
  )
}

export default ImagePointerCard
