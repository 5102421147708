import { Form } from 'react-bootstrap'
import React from 'react'

const CustomPasswordCheck = ({
  condition,
  value,
  handler,
}: {
  condition: boolean | string | undefined
  value: string
  handler: Function
}) => {
  return (
    <Form.Control
      as="input"
      className={`${
        condition === true
          ? 'alert-success'
          : condition === 'skip'
          ? 'alert-danger'
          : ''
      }`}
      disabled={condition === true || condition === 'skip'}
      value={value}
      style={condition === true ? { color: '#5CB85C' } : { color: '' }}
      onChange={event => {
        handler(event.target.value)
      }}
    ></Form.Control>
  )
}

export default CustomPasswordCheck
