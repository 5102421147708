import { clearGuide, gotoPage, setPageCondition } from '../../store/quiz/quiz'
import { useDispatch, useSelector } from 'react-redux'

import { Nav } from 'react-bootstrap'
import React from 'react'
import { RootState } from '../../store'
import { isTabletOrMobileQuery } from '../../utils'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

const StageIndicator = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    currentPage,
    data: { pages },
  } = useSelector((state: RootState) => state.quiz)

  return (
    <>
      <div
        className="table-container flex-column float-left sticky-top"
        style={{ marginBottom: 50 }}
      >
        <div id="quiz-progress-view" className="animated">
          <div>
            <div className="navigation-content">
              <div className="btn-pages">
                <Nav
                  variant="pills"
                  defaultActiveKey={currentPage}
                  activeKey={currentPage}
                  className="flex-column float-left btn-pages"
                >
                  {pages.map(page => {
                    const pageId = pages.findIndex(p => p.id === page.id)
                    return (
                      <Nav.Item key={pageId}>
                        <Nav.Link
                          className={`btn-page ${
                            page.condition === true
                              ? 'correct'
                              : page.condition === false
                              ? 'skipped'
                              : page.condition === 'skip'
                              ? 'skipped'
                              : page.condition === undefined
                              ? 'inProgress'
                              : ''
                          }
                          ${pageId === currentPage ? 'current' : ''}`}
                          eventKey={pageId}
                          onSelect={() => {
                            dispatch(gotoPage(pageId))
                            dispatch(clearGuide())
                            dispatch(
                              setPageCondition({
                                pagenumber: currentPage,
                              })
                            )
                          }}
                        ></Nav.Link>
                      </Nav.Item>
                    )
                  })}
                </Nav>
              </div>
            </div>
          </div>
          {!useMediaQuery(isTabletOrMobileQuery) ? (
            <div className="flex-navigation-title">
              <div>{t('stage_indicator.overview')}</div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  )
}

export default StageIndicator
