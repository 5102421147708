import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { IToast } from './interfaces'

const misc = createSlice({
  name: 'process',
  initialState: {
    baseUrl: '',
    eventCode: '',
    codename: '',
    currentPage: 0,
    toasts: [] as IToast[],
    successful: false,
  },
  reducers: {
    setData(state, action) {
      state.baseUrl = action.payload.baseUrl
      state.eventCode = action.payload.eventCode
      state.codename = action.payload.codename
    },
    setEvent(state, action) {
      state.eventCode = action.payload.eventCode
      state.codename = action.payload.codename
    },
    addToast(state, action: PayloadAction<IToast>) {
      state.toasts.push(action.payload)
    },
    removeToast(state, action: PayloadAction<number>) {
      state.toasts.splice(action.payload, 1)
    },
    setSuccessful(state, action) {
      state.successful = action.payload
    },
  },
})

export const {
  setData,
  addToast,
  removeToast,
  setSuccessful,
  setEvent,
} = misc.actions
export default misc.reducer
