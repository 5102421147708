import { Form } from 'react-bootstrap'
import React from 'react'

const CustomCharInput = ({
  condition,
  answerkey,
  value,
  handler,
  focus,
  status,
}: {
  condition: boolean | string | undefined
  answerkey: string
  value: string
  handler: Function
  focus: Function
  status: boolean | undefined
}) => {
  return (
    <Form.Control
      as="input"
      className={`${
        condition === true || status === true
          ? 'alert-success'
          : condition === 'skip'
          ? 'alert-danger'
          : ''
      }`}
      disabled={condition === true || condition === 'skip' || status === true}
      value={value}
      style={
        condition === true
          ? { color: '#5CB85C', textAlign: 'center', padding: '0', margin: '0' }
          : { color: '', textAlign: 'center', padding: '0', margin: '0' }
      }
      onChange={event => {
        handler(answerkey, event.target.value.toUpperCase())
      }}
      onKeyUp={(event: any) => {
        focus(event)
      }}
      maxLength={1}
    ></Form.Control>
  )
}

export default CustomCharInput
