import { ICard, IDroppedItem } from '../../../store/quiz/interfaces'
import {
  increaseAttemptCounter,
  setCardCondition,
  setDroppedItem,
  setDroppedItemStatus,
  setGuide,
  skipCorrelation,
} from '../../../store/quiz/quiz'
import { useDispatch, useSelector } from 'react-redux'

import { DndProvider } from 'react-dnd'
import DragItem from './DragItem'
import DropItem from './DropItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ModalInstructionsCorrelation from './ModalInstructionsCorrelation'
import React from 'react'
import { RootState } from '../../../store'
import { TouchBackend } from 'react-dnd-touch-backend'
import { isTouchDevice } from '../../../utils'
import { shuffle } from 'lodash'
import { usePreview } from 'react-dnd-preview'
import { useTranslation } from 'react-i18next'

const MyPreview = () => {
  const { display, itemType, item, style } = usePreview()
  if (!display) {
    return null
  }
  return (
    <div
      className="col-sm-3 col-xs-4"
      style={{
        ...style,
        ...{
          padding: '25px 25px 25px 25px',
          color: 'white',
          background: 'cornflowerblue',
          margin: '10px',
          verticalAlign: 'middle',
          zIndex: 99999999,
          transformOrigin: 'top top',
        },
      }}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: item.text,
        }}
      ></span>
    </div>
  )
}

const CorrelationCard = ({ card }: { card: ICard }) => {
  const { currentPage } = useSelector((state: RootState) => state.quiz)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // Assigning backend based on touch support on the device
  const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend

  const handleDropped = (item: IDroppedItem, choiceId: string) => {
    dispatch(
      setDroppedItem({
        page: currentPage,
        cardId: card.id,
        choiceId,
        droppedItem: item,
      })
    )
  }
  const checkData = () => {
    const answers = card.question_data.correlation_choices?.map(
      (c, i) =>
        //@ts-ignore
        card.check_data.expect[i][0] ===
          //@ts-ignore
          card.question_data.correlation_choices[i].droppedItem.key &&
        //@ts-ignore
        card.check_data.expect[i][1] ===
          //@ts-ignore
          card.question_data.correlation_choices[i].key
    )
    if (answers) {
      answers.map((c, i) => {
        if (c === true) {
          //@ts-ignore
          if (card.question_data.correlation_choices) {
            dispatch(
              setDroppedItemStatus({
                page: currentPage,
                cardId: card.id,
                status: true,
                index: i,
              })
            )
          }
        }
      })
      const correct = !answers.includes(false)
      dispatch(
        setCardCondition({
          page: currentPage,
          cardId: card.id,
          condition: correct,
        })
      )
      dispatch(
        increaseAttemptCounter({
          page: currentPage,
          cardId: card.id,
        })
      )
      dispatch(
        setGuide({
          page: currentPage,
          cardId: card.id,
          condition: correct,
        })
      )
    }
  }
  const skip = () => {
    dispatch(
      setCardCondition({
        page: currentPage,
        cardId: card.id,
        condition: 'skip',
      })
    )
    dispatch(
      setGuide({
        page: currentPage,
        cardId: card.id,
        condition: 'skip',
      })
    )
    dispatch(
      skipCorrelation({
        page: currentPage,
        cardId: card.id,
      })
    )
  }
  return (
    <DndProvider backend={backendForDND}>
      <div id={card.region} className="col-md-12">
        <div
          className={`panel ${card.type} ${card.codename} ${
            card.condition === true
              ? 'panel-success'
              : card.condition === 'skip'
              ? 'panel-danger'
              : card.condition === false
              ? 'panel-warning'
              : 'panel-default'
          }`}
        >
          {card.title ? (
            <div className="panel-heading">
              <h3 className="panel-title">{card.title}</h3>
            </div>
          ) : (
            <div></div>
          )}
          <div className="panel-body">
            <div className="card-content">
              {card.content ? (
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: card.content,
                  }}
                ></div>
              ) : (
                <div></div>
              )}
              <div className="question-text">
                <ModalInstructionsCorrelation></ModalInstructionsCorrelation>
                <div
                  dangerouslySetInnerHTML={{
                    __html: card.question_data.question_text,
                  }}
                ></div>
              </div>

              {isTouchDevice() ? (
                <div className="row choices-left-row">
                  <MyPreview />
                </div>
              ) : (
                ''
              )}

              <div className="row choices-left-row sticky-top">
                {Object.entries(card.question_data.choices_left).map(
                  ([key, text], i) => {
                    return (
                      <DragItem
                        status={undefined}
                        condition={card.condition}
                        key={key}
                        id={key}
                        value={text}
                        insideDrop={false}
                      ></DragItem>
                    )
                  }
                )}
              </div>
              <div>
                {card.question_data.correlation_choices &&
                  card.question_data.correlation_choices.map(
                    ({ key, text, droppedItem }, i) => {
                      return (
                        <DropItem
                          condition={card.condition}
                          key={key}
                          id={key}
                          text={text}
                          droppedItem={droppedItem}
                          handleDropped={handleDropped}
                        ></DropItem>
                      )
                    }
                  )}
              </div>
            </div>
          </div>
          <div className="panel-footer">
            <button
              disabled={card.condition === true || card.condition === 'skip'}
              hidden={card.condition === true || card.condition === 'skip'}
              className="btn btn-primary btn-attempt"
              onClick={checkData}
            >
              <FontAwesomeIcon icon={'check'} /> {t('question_status.check')}
            </button>
            <button
              className="btn btn-default btn-skip"
              disabled={card.condition === true || card.condition === 'skip'}
              hidden={card.condition === true || card.condition === 'skip'}
              onClick={skip}
            >
              <FontAwesomeIcon icon={'share'} /> {t('question_status.skip')}
            </button>
            <small className="pull-right text-right">
              {card.condition === true ? (
                <div>
                  <FontAwesomeIcon icon={'check'} />{' '}
                  {t('question_status.right')}
                </div>
              ) : card.condition === false ? (
                <div>
                  <FontAwesomeIcon icon={'undo'} /> {t('question_status.wrong')}
                </div>
              ) : card.condition === 'skip' ? (
                <div>
                  <FontAwesomeIcon icon={'undo'} />{' '}
                  {t('question_status.skipped')}
                </div>
              ) : (
                <div>
                  <FontAwesomeIcon icon={'minus'} />{' '}
                  {t('question_status.unedited')}
                </div>
              )}
              {t('question_status.attempts')}: {card.number_of_attempts}
            </small>
            <span className="clearfix"></span>
          </div>
        </div>
      </div>
    </DndProvider>
  )
}

export default CorrelationCard
