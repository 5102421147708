import { Form } from 'react-bootstrap'
import React from 'react'

const CustomInput = ({
  listed,
  condition,
  validText,
  value,
  handler,
  status,
}: {
  listed: boolean
  condition: boolean | string | undefined
  validText: string
  value: string
  handler: Function
  status: undefined | boolean
}) => {
  return (
    <Form.Control
      as="input"
      className={`${
        (listed === true || value === '') &&
        condition !== true &&
        condition !== 'skip'
          ? ''
          : condition === true || status === true
          ? 'alert-success'
          : condition === 'skip'
          ? 'alert-danger'
          : 'alert-danger'
      }`}
      disabled={condition === true || condition === 'skip' || status === true}
      value={value}
      style={
        condition === true || status === true
          ? { color: '#5CB85C' }
          : { color: '' }
      }
      onChange={event => {
        handler(validText, event.target.value)
      }}
    ></Form.Control>
  )
}

export default CustomInput
