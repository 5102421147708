import {
  increaseAttemptCounter,
  setCardCondition,
  setCheckedChoice,
  setGuide,
  skipChoice,
} from '../../../store/quiz/quiz'
import { useDispatch, useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ICard } from '../../../store/quiz/interfaces'
import ModalInstructionsChoice from './ModalInstructionsChoice'
import ModalInstructionsChoiceMulti from './ModalInstructionsChoiceMulti'
import React from 'react'
import { RootState } from '../../../store'
import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const ChoiceCard = ({ card }: { card: ICard }) => {
  const { currentPage } = useSelector((state: RootState) => state.quiz)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const compareArrays = function(a1: string[], a2: string[]) {
    let i = a1.length
    if (i !== a2.length) return false
    while (i--) {
      if (a1[i] !== a2[i]) return false
    }
    return true
  }

  const skip = () => {
    dispatch(
      setCardCondition({
        page: currentPage,
        cardId: card.id,
        condition: 'skip',
      })
    )
    dispatch(
      setGuide({
        page: currentPage,
        cardId: card.id,
        condition: 'skip',
      })
    )
    dispatch(
      skipChoice({
        page: currentPage,
        cardId: card.id,
      })
    )
  }

  const checkAnswer = () => {
    const right_answers = card.check_data.expect as string[]
    if (card.question_data.choice_view === 'single') {
      const answer = card.question_data.choices_checkbox.find(
        c => c.checked === true
      )
      if (answer) {
        const correct = right_answers.includes(answer.key)
        dispatch(
          setCardCondition({
            page: currentPage,
            cardId: card.id,
            condition: correct,
          })
        )
        dispatch(
          increaseAttemptCounter({
            page: currentPage,
            cardId: card.id,
          })
        )
        dispatch(
          setGuide({
            page: currentPage,
            cardId: card.id,
            condition: correct,
          })
        )
      }
    } else {
      const answer = card.question_data.choices_checkbox
        .map(c => {
          if (c.checked === true) {
            return c.key
          } else {
            return '0'
          }
        })
        .filter(e => e !== '0')
      const correct = compareArrays(answer, right_answers)
      dispatch(
        setCardCondition({
          page: currentPage,
          cardId: card.id,
          condition: correct,
        })
      )
      dispatch(
        increaseAttemptCounter({
          page: currentPage,
          cardId: card.id,
        })
      )
      dispatch(
        setGuide({
          page: currentPage,
          cardId: card.id,
          condition: correct,
        })
      )
    }
  }

  return (
    <div id={card.region} className="col-md-12">
      <div
        className={`panel ${card.type} ${card.codename} ${
          card.condition === true
            ? 'panel-success'
            : card.condition === 'skip'
            ? 'panel-danger'
            : card.condition === false
            ? 'panel-warning'
            : 'panel-default'
        }`}
      >
        {card.title ? (
          <div className="panel-heading">
            <h3 className="panel-title">{card.title}</h3>
          </div>
        ) : (
          <div></div>
        )}
        <div className="panel-body">
          <div className="card-content">
            {card.content ? (
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: card.content,
                }}
              ></div>
            ) : (
              <div></div>
            )}
            <div className="question-text">
              {card.question_data.choice_view === 'single' ? (
                <ModalInstructionsChoice></ModalInstructionsChoice>
              ) : (
                <ModalInstructionsChoiceMulti></ModalInstructionsChoiceMulti>
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html: card.question_data.question_text,
                }}
              ></div>
            </div>
          </div>
          <Row>
            {card.question_data.choices_checkbox &&
              card.question_data.choices_checkbox.map(
                ({ key, text, checked, image }) => {
                  return (
                    <>
                      <div
                        className={`${
                          card.question_data.choice_type === 'image'
                            ? 'image-view col-xs-6 col-sm-4 col-md-4 col-lg-3 radio'
                            : 'col-md-12 checkbox'
                        }`}
                      >
                        <label
                          className={`${
                            (card.condition === true && checked === true) ||
                            (card.condition === 'skip' && checked === true)
                              ? 'correct'
                              : (card.condition === true &&
                                  checked === false) ||
                                (card.condition === 'skip' && checked === true)
                              ? 'wrong'
                              : ''
                          }`}
                        >
                          <input
                            disabled={
                              card.condition === true ||
                              card.condition === 'skip'
                            }
                            type={`${
                              card.question_data.choice_view === 'single'
                                ? 'radio'
                                : 'checkbox'
                            }`}
                            value={key}
                            name={`${card.id}-${key}`}
                            checked={checked}
                            onChange={() => {
                              dispatch(
                                setCheckedChoice({
                                  page: currentPage,
                                  cardId: card.id,
                                  choiceId: key,
                                })
                              )
                            }}
                          />
                          {card.question_data.choice_type === 'image' ? (
                            <>
                              <img src={`${image}`} alt={text}></img>
                              <div className="image-label">{text}</div>
                            </>
                          ) : (card.condition === true && checked === true) ||
                            (card.condition === 'skip' && checked === true) ? (
                            <span>
                              {text} <FontAwesomeIcon icon={'check'} />
                            </span>
                          ) : (card.condition === true && checked === true) ||
                            (card.condition === 'skip' && checked === false) ? (
                            <span>
                              {text} <FontAwesomeIcon icon={'times'} />
                            </span>
                          ) : (
                            <span>{text}</span>
                          )}
                        </label>
                      </div>
                    </>
                  )
                }
              )}
          </Row>
        </div>
        <div className="panel-footer">
          <button
            className="btn btn-primary btn-attempt"
            onClick={() => {
              checkAnswer()
            }}
            disabled={card.condition === true || card.condition === 'skip'}
            hidden={card.condition === true || card.condition === 'skip'}
          >
            <FontAwesomeIcon icon={'check'} /> {t('question_status.check')}
          </button>
          <button
            className="btn btn-default btn-skip"
            onClick={skip}
            disabled={card.condition === true || card.condition === 'skip'}
            hidden={card.condition === true || card.condition === 'skip'}
          >
            <FontAwesomeIcon icon={'share'} /> {t('question_status.skip')}
          </button>
          <small className="pull-right text-right">
            {card.condition === true ? (
              <div>
                <FontAwesomeIcon icon={'check'} /> {t('question_status.right')}
              </div>
            ) : card.condition === false ? (
              <div>
                <FontAwesomeIcon icon={'undo'} /> {t('question_status.wrong')}
              </div>
            ) : card.condition === 'skip' ? (
              <div>
                <FontAwesomeIcon icon={'undo'} /> {t('question_status.skipped')}
              </div>
            ) : (
              <div>
                <FontAwesomeIcon icon={'minus'} />{' '}
                {t('question_status.unedited')}
              </div>
            )}
            {t('question_status.attempts')}: {card.number_of_attempts}
          </small>
          <span className="clearfix"></span>
        </div>
      </div>
    </div>
  )
}

export default ChoiceCard
