import { IChoiceCorrelation, IDroppedItem, IQuiz, ISchool } from './interfaces'
import { delay, isEmpty, shuffle } from 'lodash'

import { AppThunk } from '..'
import { TIMEOUT } from 'dns'
import { WritableDraft } from 'immer/dist/internal'
import { createSlice } from '@reduxjs/toolkit'
import i18next from 'i18next'

function checkPageCondition(pagenumber: number, data: IQuiz) {
  const card_conditions = data.pages[pagenumber].cards.map(e => {
    return e.condition
  })
  if (card_conditions.includes('skip')) {
    return 'skip'
  } else if (card_conditions.includes(false)) {
    return false
  } else if (card_conditions.includes(undefined)) {
    return undefined
  } else if (card_conditions.every(Boolean)) {
    return true
  }
}

function setScore(data: IQuiz) {
  let points = 0
  let cardscore = 0
  let solvedCards = 0
  let quizScore = 0
  const unsolvable = ['memory', 'card', 'chapter', 'certificate']
  const outer = data.pages.map(page => {
    const inner = page.cards.map(card => {
      if (!unsolvable.includes(card.type)) {
        if (card.condition !== true) {
          cardscore = 0
        } else {
          let maximumRemainingattepmts = 1
          let remainingAttempts = 1
          if (card.max_number_of_attempts) {
            remainingAttempts =
              card.max_number_of_attempts + 1 - card.number_of_attempts
            if (remainingAttempts < 0) {
              remainingAttempts = 0
            }
            maximumRemainingattepmts = card.max_number_of_attempts
          }
          cardscore = (remainingAttempts / maximumRemainingattepmts) * 100
        }
        solvedCards += 1
        points = points + cardscore
        quizScore = points / solvedCards
      }
      return quizScore
    })
    return inner
  })
  const Score = outer[outer.length - 1][outer[outer.length - 1].length - 1]
  if (Score > 80) {
    return '1'
  } else if (Score > 65) {
    return '2'
  } else if (Score > 45) {
    return '3'
  } else if (Score > 25) {
    return '4'
  } else if (Score > 10) {
    return '5'
  } else return '6'
}

function setTitle(
  state: WritableDraft<{
    data: IQuiz
    currentPage: number
    loaded: boolean
    error: boolean
  }>
) {
  const quiz_title = state.data.title
  const pages = state.data.pages.length
  const current_page = state.currentPage + 1
  const title = `${quiz_title} - ${current_page}/${pages} - Online-Workshop`
  document.title = title
}

const quizSlice = createSlice({
  name: 'quiz',
  initialState: {
    data: {} as IQuiz,
    currentPage: 0,
    loaded: false,
    done: false,
    error: false,
    school_choices: [] as ISchool[],
    school: '',
    class: '',
  },
  reducers: {
    setQuiz(state, action) {
      state.data = action.payload
      state.error = false
      state.loaded = true
      setTitle(state)
    },
    setSchoolChoices(state, action) {
      state.school_choices = action.payload
      if (state.loaded && state.school_choices === null) {
        state.done = true
      }
    },
    setSchool(
      state,
      action: {
        payload: {
          school: string
          class: string
        }
      }
    ) {
      state.school = action.payload.school
      state.class = action.payload.class
      state.done = true
    },
    loadingFailed(state, action) {
      state.loaded = false
      state.error = action.payload
      state.data = {} as IQuiz
    },
    gotoPage(state, action) {
      state.currentPage = action.payload
      setTitle(state)
      window.scrollTo(0, 0)
    },
    nextPage(state) {
      state.currentPage++
      setTitle(state)
      window.scrollTo(0, 0)
    },
    prevPage(state) {
      if (state.currentPage > 0) state.currentPage--
      setTitle(state)
      window.scrollTo(0, 0)
    },
    setTextAnswer(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          data: string
        }
      }
    ) {
      const { page, cardId, data } = action.payload
      //TODO: get data from ClozeCard and save to state
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card) {
        card.question_data.question_text = data
      }
    },
    setClozeAnswer(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          data: string
          key: string
        }
      }
    ) {
      const { page, cardId, data, key } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card) {
        const element = card.question_data.elements.find(e => e.key === key)
        if (element) {
          element.data = data
          element.type = 'input'
        }
        const fieldValues = card.question_data.elements
          .filter(e => e.type === 'input')
          .map(e => {
            return e.data
          })
        const keywordsObjActive = card.question_data.keywords.map(k => ({
          ...k,
          used: fieldValues.includes(k.keyword),
        }))
        card.question_data.keywords = keywordsObjActive
      }
    },
    skipCloze(
      state,
      action: {
        payload: {
          page: number
          cardId: number
        }
      }
    ) {
      const { page, cardId } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card) {
        card.question_data.elements = card.question_data.elements.map(e => {
          if (e.type === 'input') {
            return {
              type: 'input',
              data: e.key,
              key: e.key,
              status: e.status,
            }
          } else {
            return {
              type: 'text',
              data: e.data,
              key: '',
              status: e.status,
            }
          }
        })
      }
    },
    setDroppedItem(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          choiceId: string
          droppedItem: IDroppedItem
        }
      }
    ) {
      const { page, cardId, choiceId, droppedItem } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card && card.question_data.correlation_choices) {
        //check if the item was dropped somewhere else before and remove it from there before moving
        const fromChoice = card.question_data.correlation_choices.find(
          e => e.droppedItem.key === droppedItem.key
        )
        if (fromChoice) {
          fromChoice.droppedItem = {}
        }
        //remove the dropped item from the choices_left object
        const choicesWithoutDropped = Object.fromEntries(
          Object.entries(card.question_data.choices_left).filter(
            ([key]) => key !== droppedItem.key
          )
        )
        card.question_data.choices_left = choicesWithoutDropped

        //move the droppedItem to the correct correlation choice place
        const toChoice = card.question_data.correlation_choices.find(
          e => e.key === choiceId
        )
        if (toChoice) {
          if (!isEmpty(toChoice.droppedItem)) {
            if (fromChoice) {
              fromChoice.droppedItem = toChoice.droppedItem
            } else {
              //@ts-ignore
              card.question_data.choices_left[toChoice.droppedItem.key] =
                toChoice.droppedItem.text
            }
          }
          toChoice.droppedItem = droppedItem
        }
      }
    },
    setDroppedItemStatus(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          status: boolean | undefined
          index: number
        }
      }
    ) {
      const { page, cardId, status, index } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card && card.question_data.correlation_choices) {
        const correct_dropped_item =
          card.question_data.correlation_choices[index].droppedItem
        if (correct_dropped_item) {
          correct_dropped_item.status = status
        }
      }
    },
    skipCorrelation(
      state,
      action: {
        payload: {
          page: number
          cardId: number
        }
      }
    ) {
      const { page, cardId } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card && card.question_data.correlation_choices) {
        const correctDroppedItems = Object.entries(
          card.question_data.choices_left
        ).map(([k, t]) => ({ key: k, text: t }))
        card.question_data.correlation_choices = card.question_data.correlation_choices.map(
          (c, i) => ({ ...c, droppedItem: correctDroppedItems[i] })
        )
        card.question_data.choices_left = {}
      }
    },
    setCheckedChoice(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          choiceId: string
        }
      }
    ) {
      const { page, cardId, choiceId } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card && card.question_data.choices_checkbox) {
        if (card.question_data.choice_view === 'single') {
          const choices = card.question_data.choices_checkbox.map(e => ({
            ...e,
            checked: e.key === choiceId,
          }))
          card.question_data.choices_checkbox = choices
        } else {
          const choice = card.question_data.choices_checkbox.find(
            c => c.key === choiceId
          )
          if (choice) {
            if (choice.checked === true) {
              choice.checked = false
            } else {
              choice.checked = true
            }
          }
        }
      }
    },
    skipChoice(
      state,
      action: {
        payload: {
          page: number
          cardId: number
        }
      }
    ) {
      const { page, cardId } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card && card.question_data.choices_checkbox) {
        const right_answers = card.check_data.expect as string[]
        if (card.question_data.choice_view === 'single') {
          const choices = card.question_data.choices_checkbox.map(e => ({
            ...e,
            checked: e.key === right_answers[0],
          }))
          card.question_data.choices_checkbox = choices
        } else {
          const choices = card.question_data.choices_checkbox.map(e => ({
            ...e,
            checked: right_answers.includes(e.key),
          }))
          card.question_data.choices_checkbox = choices
        }
      }
    },
    setBooleanAnswer(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          answer: boolean
        }
      }
    ) {
      const { page, cardId, answer } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card) {
        card.question_data.choices_boolean.answer = answer
      }
    },
    increaseAttemptCounter(
      state,
      action: {
        payload: {
          page: number
          cardId: number
        }
      }
    ) {
      const { page, cardId } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card) {
        card.number_of_attempts += 1
      }
    },
    setClozeChoiceData(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          data: string
          key: string
        }
      }
    ) {
      const { page, cardId, data, key } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card) {
        const clozechoice = card.question_data.choices_clozechoice.find(
          e => e.key === key
        )
        if (clozechoice) {
          clozechoice.data = data
        }
      }
    },
    setClozeChoiceCheckedChoice(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          data: string
          key: string
        }
      }
    ) {
      const { page, cardId, data, key } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card) {
        const clozechoice = card.question_data.choices_clozechoice.find(
          e => e.key === key
        )
        if (clozechoice) {
          clozechoice.checked_choice = data
        }
      }
    },
    setClozeChoiceStatus(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          status: boolean | undefined
          key: string
        }
      }
    ) {
      const { page, cardId, status, key } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card && card.question_data.choices_clozechoice) {
        const correct_clozechoice = card.question_data.choices_clozechoice.find(
          c => c.key === key
        )
        if (correct_clozechoice) {
          correct_clozechoice.status = status
        }
      }
    },
    skipClozeChoice(
      state,
      action: {
        payload: {
          page: number
          cardId: number
        }
      }
    ) {
      const { page, cardId } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card) {
        card.question_data.choices_clozechoice = card.question_data.choices_clozechoice.map(
          e => {
            if (e.type === 'select') {
              return {
                type: 'select',
                key: e.key,
                choices: e.choices,
                data: e.correct_answers[0],
                correct_answers: e.correct_answers,
                checked_choice: e.correct_answers[0],
                status: true,
              }
            } else {
              return {
                type: 'text',
                data: e.data,
                key: e.key,
                choices: e.choices,
                correct_answers: e.correct_answers,
                checked_choice: '',
                status: undefined,
              }
            }
          }
        )
      }
    },
    setSequenceData(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          data: string[]
          key: number
        }
      }
    ) {
      const { page, cardId, data, key } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card && card.question_data.choices_sequencing) {
        card.question_data.choices_sequencing[key].data = data
      }
    },
    skipSequence(
      state,
      action: {
        payload: {
          page: number
          cardId: number
        }
      }
    ) {
      const { page, cardId } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card && card.question_data.choices_sequencing) {
        card.question_data.choices_sequencing = card.question_data.choices_sequencing.map(
          e => {
            return {
              type: 'text',
              data: e.choices,
              key: e.key,
              choices: e.choices,
              status: e.status,
            }
          }
        )
      }
    },
    setCardCondition(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          condition: boolean | string | undefined
        }
      }
    ) {
      const { page, cardId, condition } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card) {
        card.condition = condition
      }
      const actual_page = state.data.pages[page]
      actual_page.condition = checkPageCondition(page, state.data)
    },
    setSequenceStatus(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          status: boolean | undefined
          key: string
        }
      }
    ) {
      const { page, cardId, status, key } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card && card.question_data.choices_sequencing) {
        const correct_sequence = card.question_data.choices_sequencing.find(
          c => c.key === key
        )
        if (correct_sequence) {
          correct_sequence.status = status
        }
      }
    },
    setClozeStatus(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          status: boolean | undefined
          key: string
        }
      }
    ) {
      const { page, cardId, status, key } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card && card.question_data.elements) {
        const correct_word = card.question_data.elements.find(
          c => c.key === key
        )
        if (correct_word) {
          correct_word.status = status
        }
      }
    },
    setPageCondition(
      state,
      action: {
        payload: {
          pagenumber: number
        }
      }
    ) {
      const { pagenumber } = action.payload
      const page = state.data.pages[pagenumber]
      page.condition = checkPageCondition(pagenumber, state.data)
      state.data.score = setScore(state.data)
    },
    setMemoryItem(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          itemId: string
          flipped: boolean
          correct: boolean
        }
      }
    ) {
      const { page, cardId, itemId, correct, flipped } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card && card.question_data.items) {
        const itemIndex = card.question_data.items.findIndex(
          i => i.id === itemId
        )
        card.question_data.items[itemIndex].flipped = flipped
        card.question_data.items[itemIndex].correct = correct
      }
    },
    skipMemory(
      state,
      action: {
        payload: {
          page: number
          cardId: number
        }
      }
    ) {
      const { page, cardId } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card && card.question_data.items) {
        const flipped_cards = card.question_data.items.map(e => ({
          ...e,
          flipped: true,
          correct: true,
        }))
        card.question_data.items = flipped_cards
      }
    },
    setImagePointer(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          relativePos: number[]
          icon_top: number
          icon_left: number
          icon_visible: string
        }
      }
    ) {
      const {
        page,
        cardId,
        relativePos,
        icon_top,
        icon_left,
        icon_visible,
      } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card) {
        card.question_data.imagePointer.answer = relativePos
        card.question_data.imagePointer.icon_top = icon_top
        card.question_data.imagePointer.icon_left = icon_left
        card.question_data.imagePointer.icon_visible = icon_visible
      }
    },
    setCharAnswer(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          data: string
          key: string
        }
      }
    ) {
      const { page, cardId, data, key } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card) {
        const char = card.question_data.char.find(e => e.key === key)
        if (char) {
          char.data = data
        }
      }
    },
    setCharStatus(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          status: boolean | undefined
          key: string
        }
      }
    ) {
      const { page, cardId, status, key } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card && card.question_data.char) {
        const correct_char = card.question_data.char.find(c => c.key === key)
        if (correct_char) {
          correct_char.status = status
        }
      }
    },
    skipCharmap(
      state,
      action: {
        payload: {
          page: number
          cardId: number
        }
      }
    ) {
      const { page, cardId } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card) {
        card.question_data.char = card.question_data.char.map(e => {
          if (e.type === 'input') {
            return {
              type: 'input',
              data: e.char,
              key: e.key,
              char: e.char,
              status: e.status,
            }
          } else {
            return {
              type: 'text',
              data: e.data,
              key: '',
              char: '',
              status: e.status,
            }
          }
        })
      }
    },
    setPassword(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          data: string
        }
      }
    ) {
      const { page, cardId, data } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card) {
        card.question_data.password.password = data
      }
    },
    setPasswordSentence(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          data: string
        }
      }
    ) {
      const { page, cardId, data } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card) {
        card.question_data.password.sentence = data
      }
    },
    setGuide(
      state,
      action: {
        payload: {
          page: number
          cardId: number
          condition: boolean | string | undefined
        }
      }
    ) {
      const { page, cardId, condition } = action.payload
      const card = state.data.pages[page].cards.find(c => c.id === cardId)
      if (card) {
        if (condition === true) {
          state.data.guide = card.guide.explanation
          state.data.guide_status = true
          state.data.guide_animated = 'true'
        } else if (condition === false) {
          state.data.guide = card.guide.hint
          state.data.guide_status = false
          state.data.guide_animated = 'true'
        } else {
          state.data.guide = card.guide.explanation
          state.data.guide_status = 'skip'
          state.data.guide_animated = 'true'
        }
      }
    },
    clearGuide(state) {
      state.data.guide = ''
      state.data.guide_status = ''
      state.data.guide_animated = 'false'
    },
  },
})

export default quizSlice.reducer
export const {
  setQuiz,
  gotoPage,
  nextPage,
  prevPage,
  loadingFailed,
  setClozeAnswer,
  setTextAnswer,
  setDroppedItem,
  setCheckedChoice,
  setBooleanAnswer,
  increaseAttemptCounter,
  setClozeChoiceData,
  setSequenceData,
  setCardCondition,
  setPageCondition,
  setMemoryItem,
  setImagePointer,
  skipSequence,
  skipChoice,
  skipCloze,
  skipClozeChoice,
  skipCorrelation,
  skipMemory,
  setGuide,
  clearGuide,
  setClozeChoiceCheckedChoice,
  setCharAnswer,
  skipCharmap,
  setPassword,
  setPasswordSentence,
  setSequenceStatus,
  setClozeStatus,
  setClozeChoiceStatus,
  setCharStatus,
  setDroppedItemStatus,
  setSchoolChoices,
  setSchool,
} = quizSlice.actions

export const fetchSchools = (): AppThunk => async (dispatch, getState) => {
  console.log('Fetching Schools...')
  let { data } = getState().quiz
  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
  const { codename, eventCode } = getState().misc
  const response = await fetch(
    `${apiEndpoint}/${eventCode}/${codename}/?serve_type=schools_json`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
  data = await response.json()
  return dispatch(setSchoolChoices(data))
}

export const fetchQuiz = (): AppThunk => async (dispatch, getState) => {
  console.log('Fetching Quiz...')
  dispatch(setSchoolChoices([]))
  try {
    const { codename, eventCode } = getState().misc
    let { data } = getState().quiz
    const { done } = getState().quiz
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
    if (!done || isEmpty(data)) {
      const response = await fetch(
        `${apiEndpoint}/${eventCode}/${codename}/?serve_type=quiz_json`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )
      data = await response.json()
      i18next.changeLanguage(data.language)
      data.pages.forEach(p => {
        p.cards.forEach(c => {
          // rename the data and set number of attempts to 0, set Quizguide empty String
          c.max_number_of_attempts = c.number_of_attempts
          c.number_of_attempts = 0
          data.guide = ''

          if (c.type === 'cloze') {
            c.question_data.elements = c.question_data.text
              .split(
                /(\[[\w\d\\s\p{L}0-9|\-,. ¿¡*_./a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ]+\])/gm
              )
              .map(e => {
                if (e.startsWith('[') && e.endsWith(']')) {
                  return {
                    type: 'input',
                    data: '',
                    key: e.replace('[', '').replace(']', ''),
                    status: undefined,
                  }
                } else {
                  return {
                    type: 'text',
                    data: e.replace('<p>', '').replace('</p>', '<br />'),
                    key: '',
                    status: undefined,
                  }
                }
              })
            c.question_data.keywords = shuffle(
              c.question_data.elements
                .filter(e => e.key !== '')
                .map(e => {
                  return e.key
                })
                .map(k => ({
                  keyword: k,
                  used: false,
                }))
            )
          } else if (c.type === 'correlation') {
            c.question_data.correlation_choices = Object.entries(
              c.question_data.choices_right
            ).map(
              ([k, v]) =>
                ({
                  key: k,
                  text: v,
                  droppedItem: {} as IDroppedItem,
                } as IChoiceCorrelation)
            )
          } else if (c.type === 'choice') {
            c.question_data.choices_checkbox = Object.entries(
              c.question_data.choices
            ).map(([k, v]) => {
              return {
                key: k,
                text: v[0],
                image: v[1],
                checked: false,
              }
            })
            c.question_data.choice_type = c.choice_type
            c.question_data.choice_view = c.view
          } else if (c.type === 'boolean') {
            c.question_data.choices_boolean = {
              label_true: c.question_data.label_true,
              label_false: c.question_data.label_false,
              answer: null,
              correct_answer: c.check_data.expect as boolean,
            }
          } else if (c.type === 'clozechoice') {
            c.question_data.choices_clozechoice = c.question_data.text
              .split(
                /(\[[\w\d\\s\p{L}0-9|\-,. ¿¡*_./a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ]+\])/gm
              )
              .map(e => {
                if (e.startsWith('[') && e.endsWith(']')) {
                  if (c.view === 'radio') {
                    return {
                      type: 'select',
                      key: e.replace('[', '').replace(']', ''),
                      choices: shuffle(
                        e
                          .replace('[', '')
                          .replace(']', '')
                          .split('|')
                      ),
                      data: shuffle(
                        e
                          .replace('[', '')
                          .replace(']', '')
                          .split('|')
                      )[0],
                      correct_answers: e
                        .replace('[', '')
                        .replace(']', '')
                        .split('|'),
                      checked_choice: '',
                      status: undefined,
                    }
                  } else {
                    return {
                      type: 'select',
                      key: e.replace('[', '').replace(']', ''),
                      choices: shuffle(
                        e
                          .replace('[', '[--------|')
                          .replace('[', '')
                          .replace(']', '')
                          .split('|')
                      ),
                      data: e
                        .replace('[', '[--------|')
                        .replace('[', '')
                        .replace(']', '')
                        .split('|')[0],
                      correct_answers: e
                        .replace('[', '')
                        .replace(']', '')
                        .split('|'),
                      checked_choice: '',
                      status: undefined,
                    }
                  }
                } else {
                  return {
                    type: 'text',
                    data: e.replace('<p>', '').replace('</p>', '<br />'),
                    key: '',
                    choices: [],
                    correct_answers: [],
                    checked_choice: '',
                    status: undefined,
                  }
                }
              })
            c.question_data.choice_view = c.view
          } else if (c.type === 'sequencing') {
            c.question_data.choices_sequencing = c.question_data.text
              .split(
                /(\[[\w\d\\s\p{L}0-9|\-,. ¿¡*_./a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ]+\])/gm
              )
              .map(e => {
                if (e.startsWith('[') && e.endsWith(']')) {
                  return {
                    type: 'text',
                    data: shuffle(
                      e
                        .replace('[', '')
                        .replace(']', '')
                        .split('|')
                    ),
                    key: e,
                    choices: e
                      .replace('[', '')
                      .replace(']', '')
                      .split('|'),
                    status: undefined,
                  }
                } else {
                  return {
                    type: 'select',
                    data: [],
                    key: e,
                    choices: [],
                    status: undefined,
                  }
                }
              })
              .filter(e => e.type === 'text')
          } else if (
            c.type === 'card' ||
            c.type === 'chapter' ||
            c.type === 'certificate'
          ) {
            c.condition = true
          } else if (c.type === 'memory') {
            c.question_data.items = shuffle(
              c.question_data.items.map(i => ({
                ...i,
                flipped: false,
                correct: false,
              }))
            )
          } else if (c.type === 'imagepointer') {
            c.question_data.imagePointer = {
              answer: [],
              correct_answer: c.check_data.expect as [number[]],
              icon_top: 0,
              icon_left: 0,
              icon_visible: 'hidden',
            }
          } else if (c.type === 'charmap') {
            c.question_data.char = c.question_data.text
              .split(
                /(\[[\w\d\\s\p{L}0-9|\-,. ¿¡*_./a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ]+\])/gm
              )
              .map(e => {
                if (e.startsWith('[') && e.endsWith(']')) {
                  const ca = e
                    .replace('[', '')
                    .replace(']', '')
                    .split('')
                  return ca.map((c, i) => {
                    return {
                      type: 'input',
                      key: i.toString() + ca.toString(),
                      char: c.toUpperCase(),
                      data: '',
                      status: undefined,
                    }
                  })
                } else {
                  const t = e
                    .replace('<p>', '')
                    .replace('</p>', '')
                    .replace('?', '?CUT')
                    .replace('!', '!CUT')
                    .split('CUT')
                  return t.map(s => {
                    return {
                      type: 'text',
                      key: '',
                      char: '',
                      data: s,
                      status: undefined,
                    }
                  })
                }
              })
              .flat()
          } else if (c.type === 'password') {
            c.question_data.password = {
              password: '',
              sentence: '',
            }
          }
        })
      })
    }
    return dispatch(setQuiz(data))
  } catch (error) {
    return dispatch(loadingFailed(error.toString()))
  }
}
