import {
  increaseAttemptCounter,
  setCardCondition,
  setGuide,
  setTextAnswer,
} from '../../../store/quiz/quiz'
import { useDispatch, useSelector } from 'react-redux'

import CustomTextAnswer from './CustomTextAnswer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ICard } from '../../../store/quiz/interfaces'
import ModalInstructionsText from './ModalInstructionsText'
import React from 'react'
import { RootState } from '../../../store'
import { useTranslation } from 'react-i18next'

const TextCard = ({ card }: { card: ICard }) => {
  const { currentPage } = useSelector((state: RootState) => state.quiz)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleTextChange = (value: string) => {
    if (card.condition !== true) {
      dispatch(
        setTextAnswer({
          page: currentPage,
          cardId: card.id,
          data: value,
        })
      )
    }
  }

  const checkAnswer = (chars: number) => {
    const correct = chars >= card.check_data.min_chars
    dispatch(
      setCardCondition({
        page: currentPage,
        cardId: card.id,
        condition: correct,
      })
    )
    dispatch(
      increaseAttemptCounter({
        page: currentPage,
        cardId: card.id,
      })
    )
    dispatch(
      setGuide({
        page: currentPage,
        cardId: card.id,
        condition: correct,
      })
    )
  }

  return (
    <div id={card.region} className="col-md-12">
      <div
        className={`panel ${card.type} ${card.codename} ${
          card.condition === true
            ? 'panel-success'
            : card.condition === false
            ? 'panel-warning'
            : 'panel-default'
        }`}
      >
        {card.title ? (
          <div className="panel-heading">
            <h3 className="panel-title">{card.title}</h3>
          </div>
        ) : (
          <div></div>
        )}
        <div className="panel-body">
          <ModalInstructionsText></ModalInstructionsText>
          <p dangerouslySetInnerHTML={{ __html: card.content }}></p>
          <form className="form form-inline">
            <div className="form-group">
              <CustomTextAnswer
                condition={card.condition}
                value={card.question_data.question_text}
                handler={handleTextChange}
              ></CustomTextAnswer>
            </div>
          </form>
        </div>
        <div className="panel-footer" hidden={card.condition === true}>
          <button
            className="btn btn-primary btn-attempt"
            onClick={() => {
              checkAnswer(card.question_data.question_text.length)
            }}
            disabled={card.condition === true}
            hidden={card.condition === true}
          >
            <FontAwesomeIcon icon={'check'} /> {t('text_card.answer')}
          </button>
          <small className="pull-right text-right">
            {card.condition === true ? (
              <div>
                <FontAwesomeIcon icon={'check'} /> {t('question_status.right')}
              </div>
            ) : card.condition === false ? (
              <div>
                <FontAwesomeIcon icon={'undo'} /> {t('question_status.wrong')}
              </div>
            ) : (
              <div>
                <FontAwesomeIcon icon={'minus'} />{' '}
                {t('question_status.unedited')}
              </div>
            )}
            {t('question_status.attempts')}: {card.number_of_attempts}
          </small>
        </div>
      </div>
    </div>
  )
}

export default TextCard
