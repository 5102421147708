import {
  Action,
  AnyAction,
  Reducer,
  ThunkAction,
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'

import analytics from './analytics/analytics'
import misc from './misc/misc'
import quiz from './quiz/quiz'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import theme from './theme/theme'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [],
}

const appReducer = combineReducers({
  analytics,
  quiz,
  misc,
  theme,
})

const rootReducer: Reducer<RootState, AnyAction> = (state, action) => {
  if (action.type === 'reset') {
    storage.removeItem('persist:root')
  }
  return appReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
})

const persistor = persistStore(store)

export default store

export { persistor, rootReducer }
export type RootState = ReturnType<typeof appReducer>
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>
