import BooleanCard from './BooleanCard'
import CardCard from './CardCard'
import CertificateCard from './CertificateCard'
import ChapterCard from './ChapterCard'
import CharmapCard from './CharmapCard'
import ChoiceCard from './ChoiceCard'
import ClozeCard from './ClozeCard'
import ClozeChoiceCard from './ClozeChoiceCard'
import CorrelationCard from './CorrelationCard'
import { ICard } from '../../../store/quiz/interfaces'
import ImagePointerCard from './ImagePointerCard'
import MemoryCard from './MemoryCard'
import PasswordCard from './PasswordCard'
import React from 'react'
import { Row } from 'react-bootstrap'
import SequencingCard from './SequencingCard'
import TextCard from './TextCard'

const cardComponent = (card: ICard) => {
  switch (card.type) {
    case 'chapter':
      return <ChapterCard card={card}></ChapterCard>
    case 'card':
      return <CardCard card={card}></CardCard>
    case 'text':
      return <TextCard card={card}></TextCard>
    case 'correlation':
      return <CorrelationCard card={card}></CorrelationCard>
    case 'cloze':
      return <ClozeCard card={card}></ClozeCard>
    case 'choice':
      return <ChoiceCard card={card}></ChoiceCard>
    case 'boolean':
      return <BooleanCard card={card}></BooleanCard>
    case 'clozechoice':
      return <ClozeChoiceCard card={card}></ClozeChoiceCard>
    case 'sequencing':
      return <SequencingCard card={card}></SequencingCard>
    case 'memory':
      return <MemoryCard card={card}></MemoryCard>
    case 'imagepointer':
      return <ImagePointerCard card={card}></ImagePointerCard>
    case 'certificate':
      return <CertificateCard card={card}></CertificateCard>
    case 'charmap':
      return <CharmapCard card={card}></CharmapCard>
    case 'password':
      return <PasswordCard card={card}></PasswordCard>
    default:
      return (
        <div>
          {card.type} - {card.title}
        </div>
      )
  }
}

const QuizCard = ({ card }: { card: ICard }) => {
  return <Row>{cardComponent(card)}</Row>
}

export default QuizCard
