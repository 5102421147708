import React, { CSSProperties } from 'react'

import { ICard } from '../../../store/quiz/interfaces'
import { RootState } from '../../../store'
import { useSelector } from 'react-redux'

const ChapterCard = ({ card }: { card: ICard }) => {
  const { currentPage, data } = useSelector((state: RootState) => state.quiz)
  const page = data.pages[currentPage]
  const backgroundStyle: CSSProperties = {
    position: 'relative',
    paddingBottom: '100%',
    height: 0,
    overflow: 'hidden',
  }
  const imageStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  }
  const BASE_URL = process.env.REACT_APP_MEDIA_BASE_URL
  return (
    <div id={card.region} className="col-md-12">
      <div className={`${card.type} ${card.codename}`}>
        <div className="background" style={backgroundStyle}>
          <img
            src={card.background_img}
            alt="Hintergrund"
            style={imageStyle}
          ></img>
        </div>
        <div className="overlay well">
          <h3
            className="title"
            dangerouslySetInnerHTML={{ __html: card.title }}
          ></h3>
          <div
            dangerouslySetInnerHTML={{
              __html: card.content.replace(
                '{{MEDIA_IMAGE}}',
                `${BASE_URL}/uploads/images/${page.codename}/`
              ),
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default ChapterCard
