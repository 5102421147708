import { AppThunk } from '..'
import { ITheme } from './interfaces'
import { createSlice } from '@reduxjs/toolkit'

const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    name: 'client_app_voba',
    logo: '/rf-media/logos/mecodia_white_LL41jGf.png',
    favicon: false,
    theme_key: 'voba',
    client_title: 'Online-Workshop',
  } as ITheme,
  reducers: {
    setTheme(state, action) {
      const { name, logo, favicon, theme_key, client_title } = action.payload
      state.name = name
      state.logo = logo
      state.favicon = favicon
      state.theme_key = theme_key
      state.client_title = client_title
    },
    setFavicon(state, action) {
      const favicon = document.getElementById('favicon')
      if (favicon) {
        if (state.theme_key === 'new_ksk') {
          favicon.setAttribute('href', `${process.env.PUBLIC_URL}/ksk.ico`)
        } else {
          favicon.setAttribute('href', `${process.env.PUBLIC_URL}/mecodia.ico`)
        }
      }
    },
  },
})

export default themeSlice.reducer
export const { setTheme, setFavicon } = themeSlice.actions

export const fetchTheme = (): AppThunk => async (dispatch, getState) => {
  console.log('Fetching Theme...')
  try {
    const { codename, eventCode } = getState().misc
    let theme = getState().theme
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
    const response = await fetch(
      `${apiEndpoint}/${eventCode}/${codename}/?serve_type=theme_json`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
    theme = await response.json()
    dispatch(setFavicon(theme))

    return dispatch(setTheme(theme))
  } catch (error) {
    //error handling
    //return dispatch(loadingFailed(error.toString()))
  }
}
