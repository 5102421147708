import { Card, Col } from 'react-bootstrap'
import styled, { css, keyframes } from 'styled-components'

import { IItem } from '../../../store/quiz/interfaces'
import React from 'react'
import ReactCardFlip from 'react-card-flip'
import { tada } from 'react-animations'

const tadaAnimation = keyframes`${tada}`

const Img = styled(Card.Img)`
  width: 100px;
  height: 100px;
`
const StyledCol = styled(Col)`
  flex-grow: 0 !important;
  margin-top: 15px;
  margin-bottom: 15px;
  animation: ${props =>
    props.tada === 'true'
      ? css`
          ${tadaAnimation} 1s
        `
      : ''};
`

const MemoryCardCard = ({
  i,
  handleClick,
  codename,
}: {
  i: IItem
  handleClick: Function
  codename: string
}) => {
  const BASE_URL = process.env.REACT_APP_MEDIA_BASE_URL
  return (
    <StyledCol tada={i.correct.toString()}>
      <ReactCardFlip isFlipped={i.flipped} flipDirection="horizontal">
        <Card
          onClick={() => {
            handleClick(i)
          }}
        >
          <Img
            variant="top"
            src={`${BASE_URL}client_app/images/memory_back.png`}
          />
        </Card>
        <Card
          onClick={() => {
            handleClick(i)
          }}
        >
          <Img
            variant="top"
            src={`${BASE_URL}uploads/images/${codename}/${i.img}`}
          />
        </Card>
      </ReactCardFlip>
    </StyledCol>
  )
}

export default MemoryCardCard
